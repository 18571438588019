import {apiGetGoodsLabelAll} from "@/api/setting/goodsLabel";

import WellCache from "well-cache";

let well_cache = new WellCache({prefix: localStorage.getItem('user_uniq_id'), mode: 'IDB'})
let cache_time = 1000 * 60 * 30;
let cache_key = 'goods-label';


export async function getGoodsLabel() {
    const obj = await well_cache.getSync(cache_key);

    if (obj.data != null) {
        if (parseInt(obj.data.time) + cache_time < parseInt(new Date().getTime())) {
            await resetGoodsLabel();
        }
        return JSON.parse(obj.data.results);
    } else {
        return resetGoodsLabel();
    }
}

export async function resetGoodsLabel() {
    let res = await apiGetGoodsLabelAll();

    if (res.data.err_no == 0) {
        let data = {};
        data.results = JSON.stringify(res.data.results);
        data.time = new Date().getTime();
        well_cache.save(cache_key, data);
        res.data.results.brand_name && localStorage.setItem('brand_name', res.data.results.brand_name);
    }

    return res.data.results;
}
