import request from '@/plugins/http'

export function getAccountSettingList(param) {
    return request.post('/api/setting/getAccountSettingList', param);
}

export function createUser(param) {
    return request.post('/api/user/createUser', param)
}

export function validStatus(param) {
    return request.post('/api/user/validStatus', param)
}

export function invalidUser(param) {
    return request.post('/api/user/invalidUser', param)
}

export function resetPasswordUser(param) {
    return request.post('/api/user/resetPasswordUser', param)
}



export function deleteUser(param) {
    return request.post('/api/user/deleteUser', param)
}

export function getRoleList() {
    return request.get('/api/setting/getSimpleRoleList');
}

export function getLeftUserNumber() {
    return request.get('/api/setting/getLeftUserNumber');
}







