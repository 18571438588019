<template>
  <div id="logisticsInfo">
    <div id="panel-header">
      <span class="top-title">物流信息</span>
      <Button type="primary" class="client_create" @click="create" v-if="aCheck.rightCheck('edit_logistics_info')">
        创建新物流
      </Button>
    </div>
    <div id="filter-bar">
      <Card dis-hover>
        <Form :model="formItem" ref="search-form" inline>
          <FormItem prop="client_no">
            <Select @on-change="searchSubmit" v-model="formItem.client_no"  placeholder="请选择物流编号" class="w-200">
              <Option v-for="item in allLogisticsNo" :value="item" :key="item">{{ item }}</Option>
            </Select>
          </FormItem>

          <FormItem prop="simple_name">
            <Input @on-blur="searchSubmit" v-model="formItem.simple_name"  placeholder="请填写物流简称" class="w-200"/>
          </FormItem>

          <FormItem >
            <Button type="text" class="main-font-color" @click="cancelSearch('search-form')">清除</Button>
          </FormItem>
        </Form>
      </Card>
    </div>
    <div id="list" class="logistics_list">
      <Table :columns="columnsInfo" :data="listInfo">
        <template slot-scope="{ row }" slot="client_name">
          <strong>{{ row.client_name }}</strong>
        </template>
        <template slot-scope="{ row, index }" slot="action">
          <Button type="text" class="fs-12" size="small" @click="listEdit(row)" v-if="aCheck.rightCheck('edit_logistics_info')">
            <Icon type="ios-create"/>
            修改
          </Button>
          <Button type="text" class="fs-12" size="small" @click="listDeleteAlert(row)" v-if="aCheck.rightCheck('edit_logistics_info')">
            <Icon type="ios-trash"/>
            删除
          </Button>
        </template>
      </Table>
      <div class="page">
        <Page :total="listTotal" :page-size="pageSize" show-total show-elevator @on-change="changePage"/>
      </div>

    </div>

    <Modal v-model="modal1" :title="model_title" width="800">
      <div id="modalForm">
        <Form ref="clientFormItem" :model="clientFormItem" :label-width="100" :rules="ruleInline">
          <Row>
            <Col span="11">
              <FormItem label="物流编号" prop="client_no">
                <Input v-model="clientFormItem.client_no" disabled placeholder="请填写物流编号(建议英文简称)"></Input>
              </FormItem>
            </Col>
            <Col span="2">&nbsp;</Col>
            <Col span="11">
              <FormItem label="物流名称" prop="client_name">
                <Input v-model="clientFormItem.client_name" maxlength="200" placeholder="请填写物流名称"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span="11">
              <FormItem label="物流简称" prop="simple_name">
                <Input v-model="clientFormItem.simple_name" maxlength="200" placeholder="请填写物流简称"></Input>
              </FormItem>
            </Col>
            <Col span="2">&nbsp;</Col>
            <Col span="11">
              <FormItem label="联系人" prop="contact_person">
                <Input v-model="clientFormItem.contact_person" maxlength="200" placeholder="请填写联系人"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span="11">
              <FormItem label="手机号" prop="contact_phone">
                <Input v-model="clientFormItem.contact_phone" placeholder="请填写联系人手机号"></Input>
              </FormItem>
            </Col>
            <Col span="2">&nbsp;</Col>
            <Col span="11">
            </Col>
          </Row>
          <Row>
            <Col span="24">
              <FormItem label="联系地址" prop="address">
                <Input v-model="clientFormItem.address" maxlength="500" placeholder="请填写联系地址"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span="11">
              <FormItem label="开户名称" prop="account_name">
                <Input v-model="clientFormItem.account_name" maxlength="200" placeholder="请填写开户名称"></Input>
              </FormItem>
            </Col>
            <Col span="2">&nbsp;</Col>
            <Col span="11">
              <FormItem label="税号" prop="tax_number">
                <Input v-model="clientFormItem.tax_number" maxlength="200" placeholder="请填写税号"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span="11">
              <FormItem label="开户银行" prop="bank_name">
                <Input v-model="clientFormItem.bank_name" maxlength="200" placeholder="请填写开户银行"></Input>
              </FormItem>
            </Col>
            <Col span="2">&nbsp;</Col>
            <Col span="11">
              <FormItem label="账号" prop="bank_number">
                <Input v-model="clientFormItem.bank_number" maxlength="200" placeholder="请填写账号"></Input>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="handleReset('clientFormItem')">取消</Button>
        <Button type="primary" :loading="modal_loading" @click="createLogistics('clientFormItem')">保存</Button>
      </div>

    </Modal>
  </div>
</template>

<script>
import '@/css/common.css';
import * as logisticsUtil from '@/utils/logistics'

export default {
  name: "LogisticsInfo",
  data() {
    return {
      formItem: {
        client_no: '',
        simple_name: '',
      },
      clientFormItem: {
        id: '',
        client_no: '',
        client_name: '',
        simple_name: '',
        contact_person: '',
        contact_phone: '',
        address: '',
        account_name: '',
        tax_number: '',
        bank_name: '',
        bank_number: '',
      },
      clientFormItembak: {},
      model_title: '创建物流信息',
      modal1: false,
      modal_loading: false,
      columnsInfo: [
        {
          title: '物流编号',
          align: 'center',
          key: 'client_no'
        },
        {
          title: '物流全称',
          key: 'client_name',
          align: 'center',
          slot: 'client_name'
        },
        {
          title: '物流简称',
          align: 'center',
          key: 'simple_name'
        },
        {
          title: '联系人',
          align: 'center',
          key: 'contact_person'
        },
        {
          title: '联系电话',
          align: 'center',
          key: 'contact_phone'
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          slot: 'action',
        }
      ],
      listInfo: [],
      ruleInline: {
        client_no: [
          {required: true, message: '请填写物流编号', trigger: 'blur'}
        ],
        client_name: [
          {required: true, message: '请填写物流名称', trigger: 'blur'},
          // {pattern:/^\w{0,200}$/, message: '长度不能超过200', trigger: 'blur'}
        ],
        contact_person: [
          {required: true, message: '请填写联系人', trigger: 'blur'},
          // {pattern:/^\w{0,200}$/, message: '长度不能超过200', trigger: 'blur'}
        ],
        simple_name: [
          {required: true, message: '请填写简称', trigger: 'blur'},
          // {pattern:/^\w{0,200}$/, message: '长度不能超过200', trigger: 'blur'}
        ],
        contact_phone: [
          {required: true, message: '请填写联系人手机号', trigger: 'blur'},
          {pattern: /^[0-9]{11}$/, message: '请填写正确手机号格式', trigger: 'blur'}
        ],

      },
      listTotal: 0,
      pageSize: 0,
      allLogisticsNo: [],
      searchFlag: 0,
    };
  },
  methods: {
    extraOperate() {
      logisticsUtil.resetLogisticsAll();
    },
    handleReset(name) {
      this.clientFormItem = this.clientFormItembak;
      this.modal1 = false;
      this.modal_loading = false;
    },
    create() {
      this.modal_loading = false;
      this.model_title = '创建物流信息';
      this.modal1 = true;
      this.$axios.get('/api/transitunit/getLogisticsId')
          .then((response) => {
            if (response.data.err_no == 0) {
              this.clientFormItem = this.clientFormItembak;
              this.clientFormItem.client_no = response.data.results;
            }
          });
    },
    listEdit(row) {
      this.clientFormItem = row;
      this.modal1 = true;
    },
    listDeleteAlert(row) {
      this.$Modal.confirm({
        title: "删除物流信息",
        content: '确定删除物流信息？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          this.deleteListInfo(row.id);
        },
        onCancel: () => {
        }
      });
    },
    deleteListInfo($id) {
      this.$axios.post('/api/transitunit/deleteLogisticsListInfo', {id: $id}).then((response) => {
        if (response.data.err_no == 0) {
          this.$Message.success(response.data.results);
          this.getLogisticsList();
          this.extraOperate();
        } else {
          this.$Message.error(response.data.err_msg);
        }
      });
    },
    createLogistics(name) {
      this.modal_loading = true;

      this.$refs[name].validate((valid) => {
        if (!valid) {
          this.modal_loading = false;
          return;
        }
        this.$axios.post('/api/transitunit/createLogistics', this.clientFormItem).then((response) => {
          if (response.data.err_no == 0) {
            this.$Message.success('物流信息保存成功');
            this.$refs[name].resetFields();
            this.modal1 = false;
            this.getLogisticsList();
            this.extraOperate();
          } else {
            this.$Message.error(response.data.err_msg);
          }
          this.modal_loading = false;
        });
      });

    },
    searchSubmit() {
      this.searchFlag = 1;
      this.getLogisticsList();
    },
    cancelSearch(name) {
      this.$refs[name].resetFields();
      this.getLogisticsList();
    },
    getLogisticsList($page = 1) {
      let param = {
        params: {
          page: $page,
          client_no: this.formItem.client_no,
          simple_name: this.formItem.simple_name,
        }
      };
      this.$axios.get('/api/transitunit/getLogisticsList', param).then((response) => {
        if (response.data.err_no == 0) {
          this.listInfo = response.data.results.list;
          this.listTotal = response.data.results.total;
          this.pageSize = response.data.results.size;
        }
      });
    },
    getLogisticsAllId() {
      this.$axios.get('/api/transitunit/getLogisticsAllId')
          .then((response) => {
            if (response.data.err_no == 0) {
              this.allLogisticsNo = response.data.results;
            }
          });
    },
    changePage(page) {
      this.getLogisticsList(page);
    }
  },
  created() {
    this.aCheck.plateName = 'common_setting';
  },
  mounted() {
    this.clientFormItembak = this.clientFormItem;
    this.getLogisticsList();
    this.getLogisticsAllId();
  },
};
</script>

<style scoped>
#logisticsInfo {
  padding: 23px 40px;
  min-width: 960px;
  min-height: calc(100vh - 50px);
}

#panel-header {
  position: relative;
  border: none;
}

#modalForm {
  width: 100%;
}

.top-title {
  color: #333;
  font-size: 20px;
  line-height: 18px;
  cursor: pointer;
  font-weight: 600;
}

.client_create {
  position: absolute;
  right: 0;
  top: -8px;
}

#filter-bar {
  margin-top: 18px;
  font-size: 12px !important;
}

#list {
  margin-top: 12px;
  margin-bottom: 40px;
}

#list .page {
  margin-top: 16px;
}
</style>
<style>
#logisticsInfo .ivu-card {
  background-color: #fafafa;
}

#logisticsInfo .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#logisticsInfo .ivu-form-item {
  margin-bottom: 0;
}

#list .ivu-table-wrapper {
  border: 1px solid #f4f4f4;
  border-bottom: 0;
  border-radius: 6px;
}

#list.ivu-table td, .ivu-table th {
  border-bottom: 1px solid #f4f4f4;
}

#list .ivu-page {
  float: right;
}

.logistics_list .ivu-table-overflowX, .ivu-table-tip {
  overflow-x: hidden;
}
</style>
