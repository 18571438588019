<template>
  <div id="picture_lib" class="module-body">
    <div id="panel-header" class="panel-header">
      <span class="top-title">图片素材库</span>
      <Button type="primary" class="client_create" @click="uploadImg()" v-if="aCheck.rightCheck('edit_picture_lib')">
        <Icon type="ios-cloud-upload"/>
        上传
      </Button>
    </div>

    <!--列表筛选条件-->
    <div id="filter-bar" class="filter-bar">
      <Card dis-hover>
        <Form ref="list.search_item" :model="list.search_item" inline>
          <FormItem prop="title">
            <Input @on-blur="searchSubmit" v-model="list.search_item.title" placeholder="请填写标题" class="w-200"/>
          </FormItem>

          <FormItem>&nbsp;
            <Button type="text" class="main-font-color" @click="cancelSearch('list.search_item')">
              清除
            </Button>
          </FormItem>
        </Form>
      </Card>
    </div>

    <!--列表-->
    <div id="list">
      <Table :columns="list.columns_info" :data="list.data">
        <template slot-scope="{ row }" slot="created_at">
          <span>{{ row.created_at.substring(0, 10) }}</span>
        </template>

        <template slot-scope="{ row, index }" slot="img_url">
          <img :src="row.img_url+thumbnailUrl" class="preview-img" alt=""  @click="imgShow(index)">
        </template>

        <template slot-scope="{ row, index }" slot="action">
          <div v-if="aCheck.rightCheck('edit_picture_lib')">
            <Button type="text" size="small" @click="listEdit(row)">
              <Icon type="ios-create"/>
              修改
            </Button>
            <Button type="text" class="fs-12" size="small" @click="listDeleteAlert(row)">
              <Icon type="ios-trash"/>
              删除
            </Button>
          </div>
          <div v-else>
            <span class="color-grey">------</span>
          </div>
        </template>
      </Table>
      <div class="page">
        <Page :total="list.total" :page-size="list.size" show-total show-elevator @on-change="changePage"
              :current="list.page" show-sizer @on-page-size-change="pageSizeChange" :page-size-opts="list.page_size_opts"/>
      </div>

    </div>

    <Modal title="图片上传" v-model="add.modal">
      <div class="modalForm alignCenter">
        <Form ref="upload_file.item" :label-width="100" label-colon>
          <Row :gutter="20">
            <Col span="24">
              <FormItem label="图片" prop="specification">
                <ImageUpload ref="image_upload" @fileList="getFileList" :uploadParam="add.uploadParam"
                             :key="add.uploadParam.key"/>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="24">
              <FormItem label="标题" prop="title">
                <Input v-model="add.item.title" placeholder="请填写标题"></Input>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="closeUploadModal()">取消</Button>
        <Button type="primary" :loading="add.modal_loading" @click="saveUploadImg">保存</Button>
      </div>
    </Modal>

    <!--图片预览-->
    <ImagePreview ref="image_preview"/>

  </div>
</template>

<script>
import ImagePreview from '@/components/common/Image/ImagePreview';
import ImageUpload from '@/components/common/ImageUpload.vue';
import {apiSavePictureLib, apiGetImgLibList, apiDeletePictureLib} from "@/api/setting/pictureLib";

export default {
  name: "PictureLib",
  data() {
    return {
      add: {
        modal_loading: false,
        modal: false,
        item: {
          id: 0,
          file: '',
          title: '',
        },
        uploadParam: {
          list_length: 1,
          upload_type: 2,//2表示凭证上传
          module_type: 8,//素材库
          prefix: '',
          list: [],
          key: 0,
        }
      },
      thumbnailUrl:'?x-oss-process=image/resize,m_mfit,h_100,w_100',
      list: {
        page_size_opts: [10, 15, 20],
        size: 0,
        total: 0,
        data: [],
        search_item: {
          title: ''
        },
        columns_info: [
          {
            title: ' 缩略图',
            key: 'img_url',
            slot: 'img_url',
            align: 'center'
          },
          {
            title: '标题',
            key: 'title',
            align: 'center'
          },
          {
            title: '操作员',
            key: 'operator',
            align: 'center',
          },

          {
            title: '创建时间',
            slot: 'created_at',
            key: 'created_at',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            align: 'center',
            slot: 'action',
            width: 160,
          }
        ],
        page_size_key: 'picture_lib_size_key',
      }
    }
  },
  methods: {
    listDeleteAlert(row) {
      this.$Modal.confirm({
        title: "图片删除",
        content: '确定删除图片信息？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
           apiDeletePictureLib({id:row.id}).then(res => {
              if (res.data.err_no ==0) {
                this.$Message.success('操作成功！');
                this.getList();
              }
           });
        },
        onCancel: () => {
        }
      });
    },
    listEdit(row) {
      this.add.item.id = row.id;
      this.add.item.file = row.img_url
      this.add.item.title = row.title;

      this.add.uploadParam.list = [{url:row.img_url}];
      this.add.uploadParam.key += 1;

      this.add.modal = true;
    },
    //查看图片
    imgShow(value) {
      let img_info = []
      for (let i = 0; i < this.list.data.length; i++) {
        let tmp = {};
        tmp.url = this.list.data[i].img_url;
        img_info.push(tmp)
      }
      this.$refs.image_preview.imgPreview(value, img_info);
    },
    saveUploadImg() {
      this.add.modal_loading = true;

      if (this.add.item.file == '' || this.add.item.title == '') {
        this.$Message.error('必要数据不能为空！');
        this.add.modal_loading = false;
        return;
      }

      let param = {
        id: this.add.item.id,
        img_url: this.add.item.file,
        title: this.add.item.title
      }
      apiSavePictureLib(param).then(res => {
        if (res.data.err_no == 0) {
          this.$Message.success('添加成功!');
          this.add.item.title = '';
          this.add.item.id = 0;
          this.add.item.file = '';

          this.$refs.image_upload.resetImgUpload();

          this.getList();
          this.add.modal_loading = false;
        }
      }).catch(error => {
        this.$Message.error('操作失败！');
        this.add.modal_loading = false;
      })

    },
    closeUploadModal() {
      this.add.modal = false;
    },
    changePage(page) {
      this.list.page = page;
      this.getList();
    },
    pageSizeChange(page_size) {
      this.list.size = page_size;
      localStorage.setItem(this.list.page_size_key, page_size);
      this.getList();
    },
    getFileList(val) {
      if (val.length > 0) {
        this.add.item.file = val[0].url;
        this.add.item.title = val[0].name.substring(0, val[0].name.lastIndexOf("."));
      }
    },
    uploadImg() {
      this.add.item.id = 0;
      this.add.item.title = '';
      this.add.item.file = '';
      this.add.modal = true;
    },
    //列表搜索
    searchSubmit() {
      this.list.page = 1;
      this.getList();
    },
    cancelSearch(name) {
      this.$refs[name].resetFields();
      this.list.page = 1;
      this.getList();
    },
    setSelectedPageSize() {
      let size = localStorage.getItem(this.list.page_size_key);
      this.list.size = size ? (size > 50 ? 40 : parseInt(size)) : 0;
    },
    getList() {
      let param = {
        page: this.list.page,
        title: this.list.search_item.title,
        size: this.list.size
      };

      apiGetImgLibList(param).then(res => {
        this.list.data = res.data.results.list;
        this.list.total = res.data.results.total;
        this.list.size = res.data.results.size;
      })
    },
  },
  mounted() {
    this.setSelectedPageSize();

    this.getList();
  },
  created() {
    this.aCheck.plateName = 'common_setting';
  },
  components: {
    ImageUpload,
    ImagePreview
  }
}
</script>

<style scoped>
#panel-header {
  position: relative;
  border: none;
}

.modalForm {
  width: 100%;
}

.top-title {
  color: #333;
  font-size: 20px;
  line-height: 18px;
  cursor: pointer;
  font-weight: 600;
}

#picture_lib .ivu-btn-small {
  font-size: 12px !important;
}

#list {
  margin-top: 12px;
  margin-bottom: 40px;
}

#list .page {
  margin-top: 16px;
}

.goods-in-create {
  position: absolute;
  right: 0;
  top: -8px;
}

.client_create {
  position: absolute;
  right: 0;
  top: -8px;
}


</style>
<style>
#picture_lib .ivu-card {
  background-color: #fafafa;
}

#picture_lib .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#picture_lib .ivu-form-item {
  margin-bottom: 0;
}

#list .ivu-table-wrapper {
  border: 1px solid #f4f4f4;
  border-bottom: 0;
  border-radius: 6px;
}

#list.ivu-table td, .ivu-table th {
  border-bottom: 1px solid #f4f4f4;
}

#list .ivu-page {
  float: right;
}

#list .ivu-table-overflowX, .ivu-table-tip {
  overflow-x: hidden;
}

</style>
