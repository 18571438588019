<template>
  <div id="depart-info">
    <div id="panel-header">
      <span class="top-title">部门信息</span>
      <Button type="primary" class="client_create" @click="createOperate('client-item')" v-if="aCheck.rightCheck('edit_department_info')">
        创建部门
      </Button>
    </div>
    <div id="filter-bar">
      <Card dis-hover>
        <Form ref="search-form" :model="search" inline>
          <FormItem prop="name">
            <Input @on-blur="searchSubmit" v-model="search.name" class="w-200" placeholder="请填写部门名称"/>
          </FormItem>

          <FormItem prop="type_flag">
            <Select @on-change="searchSubmit" v-model="search.type_flag" class="w-200" placeholder="请选择部门类型">
              <Option v-for="i in common.type_info" :value="i.id">{{ i.name }}</Option>
            </Select>
          </FormItem>

          <FormItem >
            <Button type="text" class="main-font-color" @click="cancelSearch('search-form')">清除</Button>
          </FormItem>
        </Form>
      </Card>
    </div>
    <div id="list-info">
      <Table :columns="list.columns" :data="list.data">
        <template slot-scope="{ row }" slot="name">
          <strong class="pointer" @click="viewInfo(row)">{{ row.name }}</strong>
        </template>

        <template slot-scope="{ row }" slot="type_flag">
          <span v-for="item in common.type_info" v-if="item.id == row.type_flag">{{ item.name }}</span>
        </template>

        <template slot-scope="{ row }" slot="status">
          <span :class="row.status == 1 ? 'success-color' : 'danger-color'">{{ row.status == 1 ? '已生效' : '待生效' }}</span>
        </template>

        <template slot-scope="{ row, index }" slot="action">
          <Button v-show="[1].includes(row.status)" type="text" size="small" @click="viewInfo(row)" class="success-color fs-12">
            <Icon type="ios-checkmark-circle"/>
            查看
          </Button>
          <Button v-show="[1].includes(row.status)" type="text" size="small" @click="invalidInfo(row)" class="danger-color fs-12" v-if="aCheck.rightCheck('valid_department_info')">
            <Icon type="ios-checkmark-circle"/>
            失效
          </Button>
          <Button v-show="[0].includes(row.status)" type="text" size="small" @click="validInfo(row)" class="danger-color fs-12" v-if="aCheck.rightCheck('valid_department_info')">
            <Icon type="ios-checkmark-circle"/>
            生效
          </Button>
          <Dropdown v-show="[0].includes(row.status)">
            <Button size="small" type="text" class="fs-12">
              更多
              <Icon type="ios-arrow-down"></Icon>
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem v-if="aCheck.rightCheck('edit_department_info')">
                <span @click="listEdit(row)" class="f12">修改</span>
              </DropdownItem>
              <DropdownItem v-if="aCheck.rightCheck('edit_department_info')">
                <span @click="listDeleteAlert(row)" class="f12">删除</span></DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </template>
      </Table>
      <div class="page">
        <Page :total="list.total" :page-size="list.size" show-total show-elevator @on-change="changePage"/>
      </div>
    </div>

    <Modal v-model="create.modal" :title="create.modal_title" width="800">
      <div class="w-p-100">
        <Form ref="client-item" :model="create.item" :label-width="100" :rules="create.ruleInline">
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="部门名称" prop="name">
                <Input v-model="create.item.name" placeholder="请填写部门名称"></Input>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="部门地址" prop="address">
                <Input v-model="create.item.address" placeholder="请填写地址"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="类型" prop="type_flag">
                <Select v-model="create.item.type_flag" placeholder="请选择部门类型">
                  <Option v-for="i in common.type_info" :value="i.id">{{ i.name }}</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="备注" prop="remark">
                <Input v-model="create.item.remark" placeholder="请填写备注"></Input>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="createClose()">关闭</Button>
        <Button type="primary" :loading="create.modal_loading" @click="createDepartment('client-item')">保存</Button>
      </div>

    </Modal>

    <Modal v-model="view.modal" :title="view.modal_title" width="800">
      <div class="w-p-100">
        <Form ref="view-item" :model="view.item" :label-width="100" label-colon>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="部门名称" prop="name">
                <span>{{ view.item.name }}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="部门地址" prop="address">
                <span>{{ view.item.address }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="类型" prop="type_flag">
                <span v-for="i in common.type_info" v-if="view.item.type_flag == i.id">{{ i.name }}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="备注" prop="remark">
                <span>{{ view.item.remark }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="状态" prop="status">
                <span :class="view.item.status==1?'success-color':'danger-color'">{{
                    view.item.status == 1 ? '已生效' : '待生效'
                  }}</span>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="viewClose">关闭</Button>
      </div>

    </Modal>
  </div>
</template>

<script>
import * as department_util from '@/utils/department';

import {
  apiSaveDepartment,
  apiGetDepartmentList,
  apiValidDepartment,
  apiInValidDepartment,
  apiDeleteDepartmentInfo
} from "@/api/setting/departmentSetting";

export default {
  name: "DepartmentSetting",
  data() {
    return {
      common: {
        type_info: [
          {
            "id": 1,
            "name": "销售",
          },
        ]
      },
      search: {
        name: '',
        type_flag: '',
      },
      view: {
        modal: false,
        modal_title: '查看部门信息',
        item: {}
      },
      create: {
        modal_title: '创建部门信息',
        modal: false,
        modal_loading: false,
        item: {
          id: '',
          address: '',
          name: '',
          type_flag: '',
          remark: '',
        },
        ruleInline: {
          name: [
            {required: true, message: '请填写部门名称', trigger: 'blur'}
          ],
          address: [
            {Length: '400', message: '长度不能超过400', trigger: 'blur'}
          ],
        },
      },
      list: {
        columns: [
          {
            title: '部门名称',
            key: 'name',
            slot: 'name',
            align: 'center',
          },
          {
            title: '部门地址',
            key: 'address',
            align: 'center',
          },
          {
            title: '类型',
            key: 'type_flag',
            slot: 'type_flag',
            align: 'center',
          },
          {
            title: '部门人员',
            key: 'departmentTotal',
            align: 'center',
          },
          {
            title: '状态',
            key: 'status',
            slot: 'status',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            align: 'center',
            slot: 'action',
          }
        ],
        data: [],
        total: 0,
        size: 0,
      },
    };
  },
  methods: {
    viewClose() {
      this.view.modal = false;
    },
    viewInfo(row) {
      this.view.item = row;
      this.view.modal = true;
    },
    validInfo(row) {
      apiValidDepartment({id: row.id, name: row.name}).then(res => {
        if (res.data.err_no == 0) {
          this.$Message.success('操作成功！');
          this.getMainList();
          this.extraOperate();
        } else {
          this.$Message.error(res.data.err_msg);
        }
      });
    },
    invalidInfo(row) {
      apiInValidDepartment({id: row.id, name: row.name}).then(res => {
        if (res.data.err_no == 0) {
          this.$Message.success('操作成功！');
          this.getMainList();
          this.extraOperate();
        } else {
          this.$Message.error(res.data.err_msg);
        }
      });
    },
    extraOperate() {
      department_util.resetDepartmentAll();
    },
    createClose() {
      this.create.modal_loading = false;
      this.create.modal = false;
    },
    createOperate(name) {
      this.$refs[name].resetFields();
      this.create.modal = true;
      this.create.modal_loading = false;
    },
    listEdit(row) {
      this.create.item = row;
      this.create.modal_loading = false;
      this.create.modal = true;
    },
    listDeleteAlert(row) {
      this.$Modal.confirm({
        title: "删除部门信息",
        content: '确定删除部门信息？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          this.deleteListInfo(row);
        },
        onCancel: () => {
        }
      });
    },
    deleteListInfo(row) {
      apiDeleteDepartmentInfo({id: row.id, name: row.name}).then((response) => {
        if (response.data.err_no == 0) {
          this.$Message.success("删除操作成功！");
          this.getMainList();
          this.extraOperate();
        } else {
          this.$Message.error(response.data.err_msg);
        }
      });
    },
    createDepartment(name) {
      this.create.modal_loading = true;

      this.$refs[name].validate((valid) => {
        if (!valid) {
          this.create.modal_loading = false;
          return;
        }

        apiSaveDepartment(this.create.item).then((response) => {
          if (response.data.err_no == 0) {
            this.$Message.success('信息保存成功');

            this.create.modal = false;
            this.getMainList();
            this.extraOperate();

          } else {
            this.$Message.error(response.data.err_msg);
            this.create.modal_loading = false;
          }
        }).catch((e) => {
          this.create.modal_loading = false;
        });
      });

    },
    searchSubmit() {
      this.getMainList();
    },
    cancelSearch(name) {
      this.$refs[name].resetFields();
      this.getMainList();
    },
    getMainList($page = 1) {
      let param = {
        page: $page,
        name: this.search.name,
        type_flag: this.search.type_flag,
      };
      apiGetDepartmentList(param).then((response) => {
        if (response.data.err_no == 0) {
          this.list.data = response.data.results.list;
          this.list.total = response.data.results.total;
          this.list.size = response.data.results.size;
        }
      });
    },
    changePage(page) {
      this.getMainList(page);
    }
  },
  mounted() {
    this.clientFormItembak = this.clientFormItem;
    this.getMainList();
  },
  created() {
    this.aCheck.plateName = 'common_setting';
  },
};
</script>

<style scoped>
#depart-info {
  padding: 23px 40px;
  min-width: 960px;
  min-height: calc(100vh - 50px);
}

#panel-header {
  position: relative;
  border: none;
}

.top-title {
  color: #333;
  font-size: 20px;
  line-height: 18px;
  cursor: pointer;
  font-weight: 600;
}

.client_create {
  position: absolute;
  right: 0;
  top: -8px;
}

#filter-bar {
  margin-top: 18px;
  font-size: 12px !important;
}

#list-info {
  margin-top: 12px;
  margin-bottom: 40px;
}

#list-info .page {
  margin-top: 16px;
}
</style>
<style>
#depart-info .ivu-card {
  background-color: #fafafa;
}

#depart-info .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#depart-info .ivu-form-item {
  margin-bottom: 0;
}

#list-info .ivu-table-wrapper {
  border: 1px solid #f4f4f4;
  border-bottom: 0;
  border-radius: 6px;
}

#list-info.ivu-table td, .ivu-table th {
  border-bottom: 1px solid #f4f4f4;
}

#list-info .ivu-page {
  float: right;
}

#list-info .ivu-table-wrapper {
  min-height: auto;
}

#list-info .ivu-table-fixed-body {
  min-height: auto;
}

#list-info .ivu-table-overflowX, #list-info .ivu-table-tip {
  overflow-x: hidden;
}

#list-info .ivu-table-wrapper {
  overflow: visible;
}
</style>
