import request from '@/plugins/http'

export function apiCreateClientCategory(param) {
    return request.post('/api/setting/createClientCategory', param);
}

export function apiGetClientCategoryList(param) {
    return request.post('/api/setting/getClientCategoryList', param);
}

export function apiGetClientCategoryAll(param) {
    return request.post('/api/setting/getClientCategoryAll', param);
}


