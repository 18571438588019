<template>
  <div id="addressInfo">
    <div id="panel-header">
      <span class="top-title">流水类别设置</span>
      <Button type="primary" class="client_create" @click="create" v-if="aCheck.rightCheck('edit_flow_category')">创建类别
      </Button>
    </div>
    <div id="filter-bar">
      <Card dis-hover>
        <Form :model="formItem" ref="search-form" inline>
          <FormItem prop="name">
            <Input @on-blur="searchSubmit" v-model="formItem.name" class="w-200" placeholder="请填写类别名称"/>
          </FormItem>

          <FormItem>
            <Button type="text" class="main-font-color" @click="cancelSearch('search-form')">清除</Button>
          </FormItem>
        </Form>
      </Card>
    </div>
    <div id="category_list">
      <Table :columns="list.columnsInfo" :data="listInfo">
        <template slot-scope="{ row, index }" slot="action">
          <Button type="text" class="fs-12" size="small" @click="listEdit(row)" v-if="aCheck.rightCheck('edit_flow_category')">
            <Icon type="ios-create"/>
            修改
          </Button>
          <!--<Button type="text" size="small" @click="listDeleteAlert(row)">-->
          <!--<Icon type="ios-trash"/>-->
          <!--删除-->
          <!--</Button>-->
        </template>
      </Table>
      <div class="page">
        <Page :total="listTotal" :page-size="pageSize" show-total show-elevator @on-change="changePage"/>
      </div>

    </div>

    <Modal v-model="create_edit.modal" :title="create_edit.model_title" width="800">
      <div id="modalForm">
        <Form ref="create_edit.clientFormItem" :model="create_edit.clientFormItem" :label-width="100"
              :rules="ruleInline">
          <Row>
            <Col span="11">
              <FormItem label="类别名称" prop="name">
                <Input v-model="create_edit.clientFormItem.name" placeholder="请填写类别名称"></Input>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="handleReset('create_edit.clientFormItem')">取消</Button>
        <Button type="primary" :loading="create_edit.modal_loading"
                @click="createWarehouse('create_edit.clientFormItem')">保存
        </Button>
      </div>

    </Modal>
  </div>
</template>

<script>

export default {
  name: "FlowCategorySetting",
  data() {
    return {
      formItem: {
        name: '',
      },
      create_edit: {
        modal: false,
        modal_loading: false,
        model_title: '创建类别信息',
        clientFormItem: {
          id: '',
          name: '',
        },
        clientFormItembak: {},
      },

      list: {
        columnsInfo: [
          {
            title: '类别名称',
            key: 'name',
            align: 'center',
          },
          {
            title: '创建人',
            key: 'operator_name',
            align: 'center',
          },
          {
            title: '创建时间',
            key: 'created_at',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            align: 'center',
            slot: 'action',
          }
        ],
      },

      listInfo: [],
      ruleInline: {
        name: [
          {required: true, message: '请填写类别名称', trigger: 'blur'},
          {Length: '255', message: '长度不能超过200', trigger: 'blur'}
        ],
      },
      listTotal: 0,
      pageSize: 0,
      searchFlag: 0,
    };
  },
  methods: {
    handleReset(name) {
      this.$refs[name].resetFields();
      this.create_edit.modal = false;
      this.create_edit.modal_loading = false;
    },
    create() {
      this.create_edit.modal_loading = false;
      this.create_edit.model_title = '创建类别信息';
      this.create_edit.modal = true;
    },
    listEdit(row) {
      this.create_edit.clientFormItem = row;
      this.create_edit.modal = true;
      this.create_edit.modal_loading = false;
    },
    listDeleteAlert(row) {
      this.$Modal.confirm({
        title: "删除类别信息",
        content: '确定删除类别信息？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          this.deleteListInfo(row.id);
        },
        onCancel: () => {
        }
      });
    },
    deleteListInfo($id) {
      let param = {
        id: $id,
      };
      this.$axios.post('/api/setting/deleteFlowCategory', param).then((response) => {
        if (response.data.err_no == 0) {
          this.$Message.success(response.data.results);
          this.getList();
        } else {
          this.$Message.error(response.data.err_msg);
        }
      });
    },
    createWarehouse(name) {
      this.create_edit.modal_loading = true;
      let param = this.create_edit.clientFormItem;
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.$axios.post('/api/setting/createFlowCategory', param).then((response) => {
            if (response.data.err_no == 0) {
              this.$Message.success('信息保存成功');
              this.$refs[name].resetFields();
              this.create_edit.modal = false;
              this.getList();
            } else {
              this.$Message.error(response.data.err_msg);
              this.create_edit.modal_loading = false;
            }
          });
        } else {
          this.create_edit.modal_loading = false;
        }
      });

    },
    searchSubmit() {
      this.getList();
    },
    cancelSearch(name) {
      this.$refs[name].resetFields();
      this.getList();
    },
    getList($page = 1) {
      let param = {
        params: {
          page: $page,
          name: this.formItem.name,
          house_no: this.formItem.house_no,
        }
      };
      this.$axios.get('/api/setting/getFlowCategoryList', param).then((response) => {
        if (response.data.err_no == 0) {
          this.listInfo = response.data.results.list;
          this.listTotal = response.data.results.total;
          this.pageSize = response.data.results.size;
        }
      });
    },
    changePage(page) {
      this.getList(page);
    },
  },
  created() {
    this.aCheck.plateName = 'common_setting';
  },
  mounted() {
    this.getList();
  },
};
</script>

<style scoped>
#addressInfo {
  padding: 23px 40px;
  min-width: 960px;
  min-height: calc(100vh - 50px);
}

#panel-header {
  position: relative;
  border: none;
}

#modalForm {
  width: 100%;
}

.top-title {
  color: #333;
  font-size: 20px;
  line-height: 18px;
  cursor: pointer;
  font-weight: 600;
}

.client_create {
  position: absolute;
  right: 0;
  top: -8px;
}

#filter-bar {
  margin-top: 18px;
  font-size: 12px !important;
}

#category_list {
  margin-top: 12px;
  margin-bottom: 40px;
}

#category_list .page {
  margin-top: 16px;
}
</style>
<style>
#addressInfo .ivu-card {
  background-color: #fafafa;
}

#addressInfo .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#addressInfo .ivu-form-item {
  margin-bottom: 0;
}

#category_list .ivu-table-wrapper {
  border: 1px solid #f4f4f4;
  border-bottom: 0;
  border-radius: 6px;
}

#category_list.ivu-table td, .ivu-table th {
  border-bottom: 1px solid #f4f4f4;
}

#category_list .ivu-page {
  float: right;
}

#category_list .ivu-table-wrapper {
  min-height: auto;
}

#category_list .ivu-table-fixed-body {
  min-height: auto;
}

#category_list .ivu-table-overflowX, #category_list .ivu-table-tip {
  overflow-x: hidden;
}
</style>
