import request from '@/plugins/http'

export function getRoleSettingConfig() {
    return request.get('/api/setting/getRoleSettingConfig');
}

export function createRole(param) {
    return request.post('/api/setting/operateRole', param)
}

export function getRoleList(param) {
    return request.get('/api/setting/getRoleList', param);
}

export function validRoleStatus(param) {
    return request.post('/api/setting/validRoleStatus', param)
}

export function invalidRoleStatus(param) {
    return request.post('/api/setting/invalidRoleStatus', param)
}

export function deleteRole(param) {
    return request.post('/api/setting/deleteRole', param)
}






