<template>
  <div id="pay-desc" class="module-body">
    <div id="panel-header" class="panel-header">
      <span class="top-title">收付款类别</span>
      <Button type="primary" class="client_create" @click="createOperate('create_edit_item')" v-if="aCheck.rightCheck('edit_pay_desc')">
        创建类别
      </Button>
    </div>

    <div id="filter-bar">
      <Card dis-hover>
        <Form :model="search.item" ref="search-form" inline>
          <FormItem prop="name">
            <Input @on-blur="searchSubmit" v-model="search.item.name" class="w-200" placeholder="请填写类别名称"/>
          </FormItem>
          <FormItem>
            <Button type="text" class="main-font-color" @click="cancelSearch('search-form')">清除</Button>
          </FormItem>
        </Form>
      </Card>
    </div>

    <div id="category-list">
      <Table :columns="list.columns_info" :data="list.data">
        <template slot-scope="{ row, index }" slot="action">
          <div v-if="aCheck.rightCheck('edit_pay_desc')">
            <Button type="text" class="fs-12" size="small" @click="listEdit(row)">
              <Icon type="ios-create"/>
              修改
            </Button>
            <Button type="text" class="fs-12" size="small" @click="listDeleteAlert(row)">
              <Icon type="ios-trash"/>
              删除
            </Button>
          </div>
          <div v-else>
            <span class="color-grey">------</span>
          </div>
        </template>
      </Table>
      <div class="page">
        <Page :total="list.total" :page-size="list.page_size" show-total show-elevator @on-change="changePage"/>
      </div>
    </div>

    <Modal v-model="create_edit.modal" :title="create_edit.modal_title" width="800">
      <div id="modalForm">
        <Form ref="create_edit_item" :model="create_edit.item" :label-width="100"
              :rules="create_edit.ruleInline">
          <Row>
            <Col span="24">
              <FormItem label="类别名称" prop="name">
                <Input v-model="create_edit.item.name" placeholder="请填写类别名称，支持批量添加用顿号区分。例如：淘宝、京东、金属、木质等"></Input>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="handleReset('create_edit_item')">取消</Button>
        <Button type="primary" :loading="create_edit.modal_loading"
                @click="createPayDesc('create_edit_item')">保存
        </Button>
      </div>
    </Modal>


  </div>
</template>

<script>
import {apiCreatePayDesc, apiGetPayDescList, apiDeletePayDesc} from "@/api/setting/payDesc";

export default {
  name: "PayDesc",
  data() {
    return {
      search: {
        item: {
          name: ''
        },
      },
      create_edit: {
        modal: false,
        modal_loading: false,
        modal_title: '编辑类别信息',
        item: {
          id: 0,
          name: '',
        },
        ruleInline: {
          name: [
            {required: true, message: '请填写类别名称', trigger: 'blur'},
            {Length: '300', message: '长度不能超过300', trigger: 'blur'}
          ],
        },
      },
      list: {
        page_size: 0,
        total: 0,
        data: [],
        columns_info: [
          {
            title: '类别名称',
            key: 'name',
            align: 'center',
          },
          {
            title: '创建人',
            key: 'operator_name',
            align: 'center',
          },
          {
            title: '创建时间',
            key: 'updated_at',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            align: 'center',
            slot: 'action',
          }
        ]
      }
    }
  },
  methods: {
    handleReset(name) {
      this.$refs[name].resetFields();
      this.create_edit.item.id = 0;
      this.create_edit.modal = false;
      this.create_edit.modal_loading = false;
    },
    listEdit(row) {
      this.create_edit.item.id = row.id;
      this.create_edit.item.name = row.name;
      this.create_edit.modal = true;
      this.create_edit.modal_loading = false;
    },
    listDeleteAlert(row) {
      this.$Modal.confirm({
        title: "删除类别信息",
        content: '确定删除类别信息？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          apiDeletePayDesc({id:row.id}).then(res => {
            if (res.data.err_no == 0) {
              this.$Message.success(res.data.results);
              this.getPayDescList();
            } else {
              this.$Message.error(res.data.err_msg);
            }
          });
        },
        onCancel: () => {
        }
      });
    },
    getPayDescList() {
      let param = {
        page: this.list.page,
        name: this.search.item.name,
      };
      apiGetPayDescList(param).then((res) => {
        if (res.data.err_no == 0) {
          this.list.data = res.data.results.list;
          this.list.total = res.data.results.total;
          this.list.page_size = res.data.results.size;
        }
      });
    },
    createPayDesc(name) {
      this.create_edit.modal_loading = true;

      this.$refs[name].validate((valid) => {
        if (valid) {
          apiCreatePayDesc(this.create_edit.item).then((res) => {
            if (res.data.err_no == 0) {
              this.$Message.success('信息保存成功');
              this.$refs[name].resetFields();
              this.create_edit.modal = false;
              this.getPayDescList();
            } else {
              this.$Message.error(res.data.err_msg);
              this.create_edit.modal_loading = false;
            }
          }).catch(error => {
            this.create_edit.modal_loading = false;
          });
        } else {
          this.create_edit.modal_loading = false;
        }
      });
    },
    changePage(page) {
      this.list.page = page;
      this.getPayDescList();
    },
    cancelSearch(name) {
      this.$refs[name].resetFields();
      this.getPayDescList();
    },
    searchSubmit() {
      this.getPayDescList();
    },
    createOperate(name) {
      this.$refs[name].resetFields();
      this.create_edit.item.id = 0;
      this.create_edit.modal = true;
      this.create_edit.modal_loading = false;
    }
  },
  created() {
    this.aCheck.plateName = 'common_setting';
  },
  mounted() {
    this.getPayDescList()
  },
}
</script>
<style scoped>
#modalForm {
  width: 100%;
}

.top-title {
  color: #333;
  font-size: 20px;
  line-height: 18px;
  cursor: pointer;
  font-weight: 600;
}

.client_create {
  position: absolute;
  right: 0;
  top: -8px;
}

#filter-bar {
  margin-top: 18px;
  font-size: 12px !important;
}

#category-list {
  margin-top: 12px;
  margin-bottom: 40px;
}

#category-list .page {
  margin-top: 16px;
}
</style>
<style>
#pay-desc .ivu-card {
  background-color: #fafafa;
}

#pay-desc .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#pay-desc .ivu-form-item {
  margin-bottom: 0;
}

#category-list .ivu-table-wrapper {
  border: 1px solid #f4f4f4;
  border-bottom: 0;
  border-radius: 6px;
}

#category-list.ivu-table td, .ivu-table th {
  border-bottom: 1px solid #f4f4f4;
}

#category-list .ivu-page {
  float: right;
}

#category-list .ivu-table-wrapper {
  min-height: auto;
}

#category-list .ivu-table-fixed-body {
  min-height: auto;
}

#category-list .ivu-table-overflowX, #category-list .ivu-table-tip {
  overflow-x: hidden;
}

#category-list .ivu-tag {
  border: 0;
}
</style>
