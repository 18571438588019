<template>
  <div id="org-info" class="module-body">
    <div id="panel-header" class="panel-header">
      <span class="top-title">单位概况</span>
      <Button type="primary" class="client_create" @click="create" icon="ios-create" v-if="aCheck.rightCheck('edit_unit_overview')">更新</Button>
      <Button type="warning" class="cache_update" @click="cache_update" icon="ios-refresh">缓存更新</Button>
    </div>
    <div id="filter-bar">
      <Card dis-hover>
        <Form :label-width="80" inline label-colon>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="公司名称">
                <span>{{ company.name }}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="简称">
                <span class="fs-26 main-font-color">{{ company.simple_name }}</span>
                <span class="color-grey fs-12 margin-left-10">(用于导航栏左侧网站名称展示)</span>
              </FormItem>
            </Col>
          </Row>

          <Row :gutter="20">
            <Col span="12">
              <FormItem label="品牌名称">
                <span class="fs-26 main-font-color">{{ company.brand_name }}</span>
                <span class="color-grey fs-12 margin-left-10">(设置后可展示在登录页标题处)</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="联系人">
                <span>{{ company.contact_man }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="地址">
                <span>{{ company.address }}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="联系手机" prop="phone">
                <span>{{ company.phone }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="联系电话">
                <span>{{ company.telephone }}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="区号">
                <span>{{ company.area_code }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="邮政编码">
                <span>{{ company.post_code }}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="电子邮箱">
                <span>{{ company.email }}</span>
              </FormItem>
            </Col>
          </Row>
          <Divider>开票资料</Divider>
          <Row :gutter="20">
            <Col span="24">
              <FormItem label="公司抬头" prop="account_name">
                <span>{{ company.account_name }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="24">
              <FormItem label="税号" prop="tax_number">
                <span>{{ company.tax_number }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="24">
              <FormItem label="公司地址" prop="company_address">
                <span>{{ company.company_address }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="24">
              <FormItem label="电话" prop="mobile">
                <span>{{ company.mobile }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="24">
              <FormItem label="开户银行" prop="bank_name">
                <span>{{ company.bank_name }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="24">
              <FormItem label="账号" prop="bank_number">
                <span>{{ company.bank_number }}</span>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>

    <Modal v-model="add_modal" title="编辑单位信息" width="800">
      <div id="modalForm">
        <Form ref="clientFormItem" :model="clientFormItem" :label-width="100" :rules="ruleInline">
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="简称" prop="simple_name">
                <Input v-model="clientFormItem.simple_name" placeholder="建议四个字以内(用于系统名称显示)"></Input>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="公司名称" prop="name">
                <Input v-model="clientFormItem.name" placeholder="公司名称"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="品牌名称" prop="brand_name">
                <Input v-model="clientFormItem.brand_name" maxlength="6" placeholder="品牌名称，建议四个字以内"></Input>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="联系人" prop="contact_man">
                <Input v-model="clientFormItem.contact_man" placeholder="联系人"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="联系手机" prop="phone">
                <Input v-model="clientFormItem.phone" placeholder="联系手机"></Input>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="地址" prop="address">
                <Input v-model="clientFormItem.address" placeholder="地址"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="区号" prop="area_code">
                <Input v-model="clientFormItem.area_code" placeholder="区号"></Input>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="联系电话" prop="telephone">
                <Input v-model="clientFormItem.telephone" placeholder="联系电话"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="邮政编码" prop="post_code">
                <Input v-model="clientFormItem.post_code" placeholder="邮政编码"></Input>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="电子邮箱" prop="email">
                <Input v-model="clientFormItem.email" placeholder="请填写电子邮箱"></Input>
              </FormItem>
            </Col>
          </Row>

          <Divider>开票资料填写</Divider>
          <Row :gutter="20">
            <Col span="24">
              <FormItem label="公司抬头" prop="account_name">
                <Input v-model="clientFormItem.account_name" maxlength="200" placeholder="请填写公司抬头"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="24">
              <FormItem label="税号" prop="tax_number">
                <Input v-model="clientFormItem.tax_number" maxlength="200" placeholder="请填写税号"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="24">
              <FormItem label="公司地址" prop="company_address">
                <Input v-model="clientFormItem.company_address" maxlength="1000" placeholder="请填写公司地址"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="24">
              <FormItem label="电话" prop="mobile">
                <Input v-model="clientFormItem.mobile" maxlength="20" placeholder="请填写电话"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="24">
              <FormItem label="开户银行" prop="bank_name">
                <Input v-model="clientFormItem.bank_name" maxlength="200" placeholder="请填写开户银行"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="24">
              <FormItem label="账号" prop="bank_number">
                <Input v-model="clientFormItem.bank_number" maxlength="200" placeholder="请填写账号"></Input>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="handleReset('clientFormItem')">取消</Button>
        <Button type="primary" :loading="modal_loading" @click="createAddress('clientFormItem')">保存</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import * as company_util from '@/utils/company';
import * as address_util from '@/utils/address';
import * as client_util from '@/utils/client';
import * as logistics_util from '@/utils/logistics';
import * as supplier_utils from '@/utils/supplier';
import * as warehouse_util from '@/utils/warehouse';
import * as department_util from '@/utils/department';
import * as unit_util from '@/utils/setting/unit';


export default {
  name: "OrgInfo",
  data() {
    return {
      company: {
        id: '',
        simple_name: '',
        name: '',
        post_code: '',
        address: '',
        contact_man: '',
        phone: '',
        area_code: '',
        telephone: '',
        email: ''
      },
      clientFormItem: {
        id: '',
        simple_name: '',
        name: '',
        post_code: '',
        address: '',
        contact_man: '',
        phone: '',
        area_code: '',
        telephone: '',
        email: '',
        brand_name: '',
        account_name:'',
        tax_number:'',
        company_address:'',
        mobile: '',
        bank_name:'',
        bank_number:'',
      },
      add_modal: false,
      modal_loading: false,
      ruleInline: {
        name: [
          {required: true, message: '请填写名称', trigger: 'blur'}
        ],
        simple_name: [
          {required: true, message: '请填写简称', trigger: 'blur'},
          {Length: '6', message: '长度不能超过6个字符', trigger: 'blur'}
        ],
        brand_name: [
          {required: true, message: '请填写品牌名称', trigger: 'blur'},
        ],
        phone: [
          {required: true, message: '请填写联系手机', trigger: 'blur'}
        ],
        contact_man: [
          {required: true, message: '请填写联系人', trigger: 'blur'}
        ],
        address: [
          {required: true, message: '请填写地址', trigger: 'blur'}
        ],
      },
    };
  },
  methods: {
    cache_update() {
      //更新
      company_util.resetCompany();
      address_util.resetAddressAll();
      client_util.resetClientsAll();
      logistics_util.resetLogisticsAll();
      supplier_utils.resetSupplierAll();
      warehouse_util.resetWarehouseAll();
      department_util.resetDepartmentAll();
      unit_util.resetUnitAll();

      this.$Message.success('缓存更新成功');
    },
    handleReset(name) {
      this.$refs[name].resetFields();
      this.add_modal = false;
      this.modal_loading = false;
    },
    create() {
      this.modal_loading = false;
      this.add_modal = true;
      this.$axios.get('/api/setting/getCompany').then((response) => {
        if (response.data.results) {
          this.clientFormItem = response.data.results;
        }
      });
    },

    createAddress(name) {
      this.modal_loading = true;

      this.$refs[name].validate((valid) => {
        if (valid) {
          this.$axios.post('/api/setting/createCompany', this.clientFormItem).then((response) => {
                if (response.data.err_no == 0) {
                  this.$Message.success('单位信息保存成功');

                  company_util.resetCompany();
                  this.$refs[name].resetFields();
                  this.getCompany();
                  this.add_modal = false;
                } else {
                  this.$Message.error(response.data.err_msg);
                }
                this.modal_loading = false;
              });
        } else {
          this.modal_loading = false;
        }
      });

    },

    getCompany() {
      this.$axios.get('/api/setting/getCompany').then((response) => {
        this.company = response.data.results;
      });
    },
  },
  created() {
    this.aCheck.plateName = 'common_setting';
  },
  mounted() {
    this.getCompany();
  },
};
</script>

<style scoped>
#modalForm {
  width: 100%;
}

.client_create {
  position: absolute;
  right: 120px;
  top: -8px;
}
.cache_update {
  position: absolute;
  right: 0;
  top: -8px;
}

#filter-bar {
  margin-top: 18px;
}
</style>
<style>
#org-info .ivu-card {
  background-color: #fafafa;
}

#org-info .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#org-info .ivu-form-item {
  margin-bottom: 0;
}

#org-info .ivu-form .ivu-form-item-label {
  font-size: 12px !important;
}

#org-info .ivu-btn-small {
  font-size: 12px !important;
}

#org-info .ivu-input, #org-info .ivu-select-input {
  font-size: 12px !important;
}

#org-info .ivu-select-small.ivu-select-single .ivu-select-selection .ivu-select-placeholder, .ivu-select-small.ivu-select-single .ivu-select-selection .ivu-select-selected-value {
  font-size: 12px !important;
}
</style>
