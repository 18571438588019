import request from '@/plugins/http'

export function apiCreateBuyCategory(param) {
    return request.post('/api/setting/createBuyCategory', param);
}

export function apiGetSupplierCategoryList(param) {
    return request.post('/api/setting/getSupplierCategoryList', param);
}

export function apiGetSupplierCategoryAll(param) {
    return request.post('/api/setting/getSupplierCategoryAll', param);
}


