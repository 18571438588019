<template>
  <div id="addressInfo">
    <div id="panel-header">
      <span class="top-title">地址信息</span>
      <Button type="primary" class="client_create" @click="create" v-if="aCheck.rightCheck('edit_address_info')">创建新地址
      </Button>
    </div>
    <div id="filter-bar">
      <Card dis-hover>
        <Form :model="formItem" inline ref="filter_bar">
          <FormItem prop="name">
            <Input @on-blur="searchSubmit" v-model="formItem.name" placeholder="请填写收件人姓名"/>
          </FormItem>

          <FormItem prop="phone">
            <Input @on-blur="searchSubmit" v-model="formItem.phone" placeholder="请填写收件人手机号"/>
          </FormItem>

          <FormItem >
            <Button type="text" class="main-font-color" @click="cancelSearch('filter_bar')">清除</Button>
          </FormItem>
        </Form>
      </Card>
    </div>
    <div id="address_list">
      <Table :columns="columnsInfo" :data="listInfo">
        <template slot-scope="{ row }" slot="name">
          <strong>{{ row.name }}</strong>
        </template>

        <template slot-scope="{ row, index }" slot="action">
          <Button type="text" size="small" @click="listEdit(row)" class="fs-12">
            <Icon type="ios-create"/>
            修改
          </Button>
          <Button type="text" size="small" @click="listDeleteAlert(row)" class="fs-12">
            <Icon type="ios-trash"/>
            删除
          </Button>
        </template>
      </Table>
      <div class="page">
        <Page :total="listTotal" :page-size="pageSize" show-total show-elevator @on-change="changePage"/>
      </div>
    </div>

    <Modal v-model="modal1" :title="model_title" width="800">
      <div id="modalForm">
        <Form ref="clientFormItem" :model="clientFormItem" :label-width="100" :rules="ruleInline">
          <Row>
            <Col span="11">
              <FormItem label="收货人姓名" prop="name">
                <Input v-model="clientFormItem.name" placeholder="请填写收货人姓名"></Input>
              </FormItem>
            </Col>
            <Col span="2">&nbsp;</Col>
            <Col span="11">
              <FormItem label="手机号" prop="phone">
                <Input v-model="clientFormItem.phone" placeholder="请填写收货人手机号"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span="24">
              <FormItem label="详细地址" prop="address">
                <Input v-model="clientFormItem.address" maxlength="500" placeholder="请填写详细地址"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span="11">
              <FormItem label="邮政编码" prop="post_code">
                <Input v-model="clientFormItem.post_code" placeholder="请填写邮政编码"></Input>
              </FormItem>
            </Col>
            <Col span="2">&nbsp;</Col>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="handleReset('clientFormItem')">取消</Button>
        <Button type="primary" :loading="modal_loading" @click="createAddress('clientFormItem')">保存</Button>
      </div>

    </Modal>
  </div>
</template>

<script>
import * as address_util from '@/utils/address';

export default {
  name: "AddressManage",
  data() {
    return {
      formItem: {
        name: '',
        phone: '',
      },
      clientFormItem: {
        id: '',
        address: '',
        name: '',
        post_code: '',
        phone: '',
      },
      clientFormItembak: {},
      model_title: '创建地址信息',
      modal1: false,
      modal_loading: false,
      columnsInfo: [
        {
          title: '收货人姓名',
          key: 'name',
          align: 'center',
          slot: 'name'
        },
        {
          title: '收货人手机号',
          align: 'center',
          key: 'phone'
        },
        {
          title: '具体地址信息',
          align: 'center',
          key: 'address',
          minWidth: 250,
        },
        {
          title: '邮政编码',
          align: 'center',
          key: 'post_code'
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          slot: 'action',
        }
      ],
      listInfo: [],
      ruleInline: {
        name: [
          {required: true, message: '请填写收货人姓名', trigger: 'blur'}
        ],
        address: [
          {required: true, message: '请填写地址名称', trigger: 'blur'},
          {Length: '400', message: '长度不能超过400', trigger: 'blur'}
        ],
        phone: [
          {required: true, message: '请填写联系人手机号', trigger: 'blur'},
          {Length: '11', message: '请填写正确手机号格式', trigger: 'blur'}
        ]
      },
      listTotal: 0,
      pageSize: 0,
    };
  },
  methods: {
    extraOperate() {
      address_util.resetAddressAll();
    },
    handleReset(name) {
      this.clientFormItem = this.clientFormItembak;
      this.modal1 = false;
      this.modal_loading = false;
    },
    create() {

      this.model_title = '创建地址信息';
      this.modal1 = true;
      this.modal_loading = false;
      this.$axios.get('/api/transitunit/getLogisticsId')
          .then((response) => {
            if (response.data.err_no == 0) {
              this.clientFormItem = this.clientFormItembak;
              this.clientFormItem.client_no = response.data.results;
            }
          });
    },
    listEdit(row) {
      this.clientFormItem = row;
      this.modal1 = true;
      this.modal_loading = false;
    },
    listDeleteAlert(row) {
      this.$Modal.confirm({
        title: "删除地址信息",
        content: '确定删除地址信息？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          this.deleteListInfo(row.id);
        },
        onCancel: () => {
        }
      });
    },
    deleteListInfo($id) {
      this.$axios.post('/api/setting/deleteAddressInfo', {id: $id}).then((response) => {
        if (response.data.err_no == 0) {
          this.$Message.success(response.data.results);
          this.getAddressList();
          this.extraOperate();
        } else {
          this.$Message.error(response.data.err_msg);
        }
      });
    },
    createAddress(name) {
      this.modal_loading = true;

      this.$refs[name].validate((valid) => {
        if (!valid) {
          this.modal_loading = false;
          return;
        }

        this.$axios.post('/api/setting/createAddress', this.clientFormItem).then((response) => {
          if (response.data.err_no == 0) {
            this.$Message.success('地址信息保存成功');
            this.$refs[name].resetFields();
            this.modal1 = false;
            this.getAddressList();
            this.extraOperate();
          } else {
            this.$Message.error(response.data.err_msg);
            this.modal_loading = false;
          }
        });
      });

    },
    searchSubmit() {
      this.getAddressList();
    },
    cancelSearch(name) {
      this.$refs[name].resetFields();
      this.getAddressList();
    },
    getAddressList($page = 1) {
      let param = {
        params: {
          page: $page,
          name: this.formItem.name,
          phone: this.formItem.phone,
        }
      };
      this.$axios.get('/api/setting/getAddressList', param).then((response) => {
        if (response.data.err_no == 0) {
          this.listInfo = response.data.results.list;
          this.listTotal = response.data.results.total;
          this.pageSize = response.data.results.size;
        }
      });
    },
    changePage(page) {
      this.getAddressList(page);
    }
  },
  mounted() {
    this.clientFormItembak = this.clientFormItem;
    this.getAddressList();
  },
  created() {
    this.aCheck.plateName = 'common_setting';
  },
};
</script>

<style scoped>
#addressInfo {
  padding: 23px 40px;
  min-width: 960px;
  min-height: calc(100vh - 50px);
}

#panel-header {
  position: relative;
  border: none;
}

#modalForm {
  width: 100%;
}

.top-title {
  color: #333;
  font-size: 20px;
  line-height: 18px;
  cursor: pointer;
  font-weight: 600;
}

.client_create {
  position: absolute;
  right: 0;
  top: -8px;
}

#filter-bar {
  margin-top: 18px;
  font-size: 12px !important;
}

#address_list {
  margin-top: 12px;
  margin-bottom: 40px;
}

#address_list .page {
  margin-top: 16px;
}
</style>
<style>
#addressInfo .ivu-card {
  background-color: #fafafa;
}

#addressInfo .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#addressInfo .ivu-form-item {
  margin-bottom: 0;
}

#address_list .ivu-table-wrapper {
  border: 1px solid #f4f4f4;
  border-bottom: 0;
  border-radius: 6px;
}

#address_list.ivu-table td, .ivu-table th {
  border-bottom: 1px solid #f4f4f4;
}

#address_list .ivu-page {
  float: right;
}

#address_list .ivu-table-wrapper {
  min-height: auto;
}

#address_list .ivu-table-fixed-body {
  min-height: auto;
}

#address_list .ivu-table-overflowX, #address_list .ivu-table-tip {
  overflow-x: hidden;
}
</style>
