import {apiGetWarehouseAll,} from "@/api/setting/warehouse";
import WellCache from "well-cache";

let well_cache = new WellCache({prefix: localStorage.getItem('user_uniq_id'), mode: 'IDB'})
let cache_time = 1000 * 60 * 30;
let cache_key = 'warehouse-info';

export async function getWarehouseAll() {
    const warehouse = await well_cache.getSync(cache_key);

    if (warehouse.data != null) {
        if (parseInt(warehouse.data.time) + cache_time < parseInt(new Date().getTime())) {
            await resetWarehouseAll()
        }
        return JSON.parse(warehouse.data.results);
    } else {
        return resetWarehouseAll();
    }
}

//更新仓库信息
export async function resetWarehouseAll() {
    let res = await apiGetWarehouseAll();
    if (res.data.err_no == 0) {
        let data = {};
        data.results = JSON.stringify(res.data.results);
        data.time = new Date().getTime();
        well_cache.save(cache_key, data);
    }

    return res.data.results;
}
