<template>
  <div id="main">
    <div id="panel-header">
      <span class="top-title">仓库库位设置</span>
      <Button type="primary" class="client_create" @click="create" v-if="aCheck.rightCheck('edit_location_setting')">
        创建新库位
      </Button>
    </div>
    <div id="filter-bar">
      <Card dis-hover>
        <Form :model="formItem" ref="search-form" inline>
          <FormItem prop="house_id">
            <Select v-model="formItem.house_id" placeholder="请选择仓库" @on-change="getHouseAreaAll" class="w-200">
              <Option v-for="item in house_all" :value="item.house_id" :key="item.house_id">{{ item.house_name }}({{ item.house_no }})</Option>
            </Select>
          </FormItem>

          <FormItem prop="area_id">
            <Select @on-change="searchSubmit" v-model="formItem.area_id" placeholder="请先选择仓库" class="w-200">
              <Option v-for="item in area_all" :value="item.id" :key="item.id">{{ item.area_name }}({{ item.area_no }})</Option>
            </Select>
          </FormItem>

          <FormItem prop="position_name">
            <Input @on-blur="searchSubmit" v-model="formItem.position_name" placeholder="请填写库位名称" class="w-200"/>
          </FormItem>

          <FormItem prop="position_no">
            <Input @on-blur="searchSubmit" v-model="formItem.position_no" placeholder="请填写库位编号" class="w-200"/>
          </FormItem>

          <FormItem >
            <Button type="text" class="main-font-color" @click="cancelSearch('search-form')">清除</Button>
          </FormItem>
        </Form>
      </Card>
    </div>
    <div id="house_position_list">
      <Table :columns="list.columnsInfo" :data="listInfo">
        <template slot-scope="{ row }" slot="position_no">
          <strong>{{ row.position_no }}</strong>
        </template>

        <template slot-scope="{ row, index }" slot="action">
          <Button type="text" class="fs-12" size="small" @click="listEdit(row)" v-if="aCheck.rightCheck('edit_location_setting')">
            <Icon type="ios-create"/>
            修改
          </Button>
          <Button type="text" class="fs-12" size="small" @click="listDeleteAlert(row)" v-if="aCheck.rightCheck('edit_location_setting')">
            <Icon type="ios-trash"/>
            删除
          </Button>
        </template>
      </Table>
      <div class="page">
        <Page :total="listTotal" :page-size="pageSize" show-total show-elevator @on-change="changePage"/>
      </div>

    </div>

    <Modal v-model="create_edit.modal" :title="create_edit.model_title" width="800">
      <div id="modalForm">
        <Form ref="create_edit.clientFormItem" :model="create_edit.clientFormItem" :label-width="100" :rules="ruleInline">
          <Row>
            <Col span="11">
              <FormItem label="库位编号" prop="position_no">
                <Input v-model="create_edit.clientFormItem.position_no" disabled placeholder="请填写库位编号"></Input>
              </FormItem>
            </Col>
            <Col span="2">&nbsp;</Col>
            <Col span="11">
              <FormItem label="库位名称" prop="position_name">
                <Input v-model="create_edit.clientFormItem.position_name" placeholder="请填写库位名称"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span="11">
              <FormItem label="所在仓库" prop="house_id">
                <Select v-model="create_edit.clientFormItem.house_id" placeholder="请选择库位所在仓库" filterable @on-change="getHouseAreaAll">
                  <Option v-for="item in house_all" :value="item.house_id" :key="item.house_id">{{ item.house_name }}({{ item.house_no }})</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="2">&nbsp;</Col>
            <Col span="11">
              <FormItem label="所在库区" prop="area_id">
                <Select v-model="create_edit.clientFormItem.area_id" placeholder="请选择库位所在库区" filterable>
                  <Option v-for="item in area_all" :value="item.id" :key="item.id">{{ item.area_name }}({{ item.area_no }})</Option>
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span="11">
              <FormItem label="备注" prop="remark">
                <Input v-model="create_edit.clientFormItem.remark" maxlength="255" placeholder="请填写备注信息"></Input>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="handleReset('create_edit.clientFormItem')">取消</Button>
        <Button type="primary" :loading="create_edit.modal_loading" @click="createWarehousePosition('create_edit.clientFormItem')">
          保存
        </Button>
      </div>

    </Modal>
  </div>
</template>

<script>
export default {
  name: "HousePositionSetting",
  data() {
    return {
      formItem: {
        position_no: '',
        position_name: '',
        house_id: '',
        area_id: ''
      },
      create_edit: {
        modal: false,
        modal_loading: false,
        model_title: '创建库位信息',
        clientFormItem: {
          id: '',
          position_no: '',
          position_name: '',
          house_id: '',
          area_id: '',
          remark: '',
        },
        clientFormItembak: {},
      },

      list: {
        columnsInfo: [
          {
            title: '库位编号',
            key: 'position_no',
            slot: 'position_no'
          },
          {
            title: '库位名称',
            key: 'position_name'
          },
          {
            title: '关联仓库',
            key: 'house_name'
          },
          {
            title: '关联库区',
            key: 'area_name'
          },
          {
            title: '备注',
            key: 'remark',
            width: 260,
          },
          {
            title: '创建时间',
            key: 'created_at',
            width: 180,
          },
          {
            title: '操作',
            key: 'action',
            align: 'center',
            slot: 'action',
            width: 160,
          }
        ],
      },

      listInfo: [],
      ruleInline: {
        position_no: [
          {required: true, message: '请填写库位编号', trigger: 'blur'}
        ],
        position_name: [
          {required: true, message: '请填写库位名称', trigger: 'blur'},
          {Length: '255', message: '长度不能超过200', trigger: 'blur'}
        ],
        // house_id: [
        // 	{required: true, message: '请选择关联仓库', trigger: 'change'},
        // ],
      },
      listTotal: 0,
      pageSize: 0,
      house_all: [],
      area_all: [],
    };
  },
  methods: {
    handleReset(name) {
      this.create_edit.clientFormItem = this.create_edit.clientFormItembak;
      this.create_edit.modal = false;
      this.create_edit.modal_loading = false;
    },

    //获取仓库所有库区
    getHouseAreaAll($house_id) {
      this.$axios.get('/api/setting/getHouseAreaAll?house_id=' + $house_id).then((response) => {
        if (response.data.err_no == 0) {
          this.area_all = response.data.results;
        }
      });

      this.searchSubmit();
    },

    //获取最新编号
    create() {
      this.create_edit.modal_loading = false;
      this.create_edit.model_title = '创建库位信息';
      this.create_edit.modal = true;
      this.area_all = [];
      this.$axios.get('/api/setting/getWarehousePositionId')
          .then((response) => {
            if (response.data.err_no == 0) {
              this.create_edit.clientFormItem = this.create_edit.clientFormItembak;
              this.create_edit.clientFormItem.position_no = response.data.results;
            }
          });
    },
    listEdit(row) {
      this.create_edit.clientFormItem = row;
      this.getHouseAreaAll(row.house_id);
      this.create_edit.modal = true;
      this.create_edit.modal_loading = false;
    },
    listDeleteAlert(row) {
      this.$Modal.confirm({
        title: "删除仓库库位信息",
        content: '确定删除仓库库位信息？仓库库位信息只有在仓库库位货品库存为空时才能删除！！！',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          this.deleteListInfo(row.id);
        },
        onCancel: () => {
        }
      });
    },
    deleteListInfo($id) {
      let param = {
        id: $id,
      };
      this.$axios.post('/api/setting/deleteWarehousePosition', param)
          .then((response) => {
            if (response.data.err_no == 0) {
              this.$Message.success(response.data.results);
              this.getWarehousePositionList();
            } else {
              this.$Message.error(response.data.err_msg);
            }
          });
    },

    //创建库位
    createWarehousePosition(name) {
      this.create_edit.modal_loading = true;
      let param = this.create_edit.clientFormItem;
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.$axios
              .post('/api/setting/createWarehousePosition', param)
              .then((response) => {
                if (response.data.err_no == 0) {
                  this.$Message.success('信息保存成功');
                  this.$refs[name].resetFields();
                  this.create_edit.modal = false;
                  this.getWarehousePositionList();
                } else {
                  this.$Message.error(response.data.err_msg);
                  this.create_edit.modal_loading = false;
                }

              });
        } else {
          this.create_edit.modal_loading = false;
        }
      });

    },
    searchSubmit() {
      this.getWarehousePositionList();
    },
    cancelSearch(name) {
      this.$refs[name].resetFields();
      this.getWarehousePositionList();
    },

    //获取仓库库位
    getWarehousePositionList($page = 1) {
      let param = {
        params: {
          page: $page,
          position_no: this.formItem.position_no,
          position_name: this.formItem.position_name,
          area_id: this.formItem.area_id,
          house_id: this.formItem.house_id,
        }
      };
      this.$axios.get('/api/setting/getWarehousePositionList', param).then((response) => {
        if (response.data.err_no == 0) {
          this.listInfo = response.data.results.list;
          this.listTotal = response.data.results.total;
          this.pageSize = response.data.results.size;
        }
      });
    },
    changePage(page) {
      this.getWarehousePositionList(page);
    },
    //获取所有可用仓库列表
    getWarehouseAll() {
      this.$axios.get('/api/setting/getWarehouseAll')
          .then((response) => {
            if (response.data.err_no == 0) {
              this.house_all = response.data.results;
            }
          });
    }
  },
  created() {
    this.aCheck.plateName = 'common_setting';
  },
  mounted() {
    this.create_edit.clientFormItembak = this.create_edit.clientFormItem;
    this.getWarehousePositionList();
    this.getWarehouseAll();
  },
};
</script>

<style scoped>
#main {
  padding: 23px 40px;
  min-width: 960px;
  min-height: calc(100vh - 50px);
}

#panel-header {
  position: relative;
  border: none;
}

#modalForm {
  width: 100%;
}

.top-title {
  color: #333;
  font-size: 20px;
  line-height: 18px;
  cursor: pointer;
  font-weight: 600;
}

.client_create {
  position: absolute;
  right: 0;
  top: -8px;
}

#filter-bar {
  margin-top: 18px;
  font-size: 12px !important;
}

#house_position_list {
  margin-top: 12px;
  margin-bottom: 40px;
}

#house_position_list .page {
  margin-top: 16px;
}
</style>
<style>
#main .ivu-card {
  background-color: #fafafa;
}

#main .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#main .ivu-form-item {
  margin-bottom: 0;
}

#house_position_list .ivu-table-wrapper {
  border: 1px solid #f4f4f4;
  border-bottom: 0;
  border-radius: 6px;
}

#house_position_list.ivu-table td, .ivu-table th {
  border-bottom: 1px solid #f4f4f4;
}

#house_position_list .ivu-page {
  float: right;
}

#house_position_list .ivu-table-wrapper {
  min-height: auto;
}

#house_position_list .ivu-table-fixed-body {
  min-height: auto;
}

#house_position_list .ivu-table-overflowX, #house_position_list .ivu-table-tip {
  overflow-x: hidden;
}
</style>
