<template>
  <div id="goods-label" class="module-body">
    <div id="panel-header" class="panel-header">
      <span class="top-title">货品标签设置</span>
      <Button type="primary" class="client_create" @click="create" v-if="aCheck.rightCheck('edit_goods_label')">创建标签
      </Button>
    </div>
    <div id="filter-bar">
      <Card dis-hover>
        <Form :model="formItem" ref="search-form" inline>
          <FormItem prop="name">
            <Input @on-blur="searchSubmit" v-model="formItem.name" class="w-200" placeholder="请填写标签名称"/>
          </FormItem>

          <FormItem>
            <Button type="text" class="main-font-color" @click="cancelSearch('search-form')">清除</Button>
          </FormItem>
        </Form>
      </Card>
    </div>
    <div id="label-list">
      <Table :columns="list.columnsInfo" :data="listInfo">
        <template slot-scope="{ row, index }" slot="name">
          <Tag :class="getLabelColor(row.color)" size="large"><span style="color:#fff;">{{row.name}}</span></Tag>
        </template>

        <template slot-scope="{ row, index }" slot="action">
          <Button type="text" class="fs-12" size="small" @click="listEdit(row)" v-if="aCheck.rightCheck('edit_goods_label')">
            <Icon type="ios-create"/>
            修改
          </Button>
        </template>
      </Table>
      <div class="page">
        <Page :total="listTotal" :page-size="pageSize" show-total show-elevator @on-change="changePage"/>
      </div>

    </div>

    <Modal v-model="create_edit.modal" :title="create_edit.model_title" width="800">
      <div id="modalForm">
        <Form ref="create_edit.clientFormItem" :model="create_edit.clientFormItem" :label-width="100"
              :rules="ruleInline">
          <Row>
            <Col span="24">
              <FormItem label="标签名称" prop="name">
                <Input v-model="create_edit.clientFormItem.name" placeholder="请填写标签名称"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span="24">
              <FormItem label="标签颜色" prop="name">
                <div style="display: flex;">
                  <div class="label-color-one label-info" @click="setLabelColor('one')">
                    <Icon type="md-checkmark" v-show="create_edit.clientFormItem.color == 'one'"/>
                  </div>
                  <div class="label-color-two label-info" @click="setLabelColor('two')">
                    <Icon type="md-checkmark" v-show="create_edit.clientFormItem.color == 'two'"/>
                  </div>
                  <div class="label-color-three label-info" @click="setLabelColor('three')">
                    <Icon type="md-checkmark" v-show="create_edit.clientFormItem.color == 'three'"/>
                  </div>
                  <div class="label-color-four label-info" @click="setLabelColor('four')">
                    <Icon type="md-checkmark" v-show="create_edit.clientFormItem.color == 'four'"/>
                  </div>
                  <div class="label-color-five label-info" @click="setLabelColor('five')">
                    <Icon type="md-checkmark" v-show="create_edit.clientFormItem.color == 'five'"/>
                  </div>
                  <div class="label-color-six label-info" @click="setLabelColor('six')">
                    <Icon type="md-checkmark" v-show="create_edit.clientFormItem.color == 'six'"/>
                  </div>
                  <div class="label-color-seven label-info" @click="setLabelColor('seven')">
                    <Icon type="md-checkmark" v-show="create_edit.clientFormItem.color == 'seven'"/>
                  </div>
                  <div class="label-color-eight label-info" @click="setLabelColor('eight')">
                    <Icon type="md-checkmark" v-show="create_edit.clientFormItem.color == 'eight'"/>
                  </div>
                  <div class="label-color-nine label-info" @click="setLabelColor('nine')">
                    <Icon type="md-checkmark" v-show="create_edit.clientFormItem.color == 'nine'"/>
                  </div>
                  <div class="label-color-ten label-info" @click="setLabelColor('ten')">
                    <Icon type="md-checkmark" v-show="create_edit.clientFormItem.color == 'ten'"/>
                  </div>
                </div>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="handleReset('create_edit.clientFormItem')">取消</Button>
        <Button type="primary" :loading="create_edit.modal_loading"
                @click="createGoodsLabel('create_edit.clientFormItem')">保存
        </Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import * as label_util from '@/utils/goodsLabel';

import {
  apiSaveGoodsLabel,
  apiGetGoodsLabelList
} from "@/api/setting/goodsLabel";

export default {
  name: "GoodsLabelSet",
  data() {
    return {
      formItem: {
        name: '',
      },
      create_edit: {
        modal: false,
        modal_loading: false,
        model_title: '创建标签信息',
        clientFormItem: {
          id: '',
          name: '',
          color: '',
        },
      },

      list: {
        columnsInfo: [
          {
            title: '标签名称',
            key: 'name',
            slot: 'name',
            align: 'center',
          },
          {
            title: '创建人',
            key: 'operator_name',
            align: 'center',
          },
          {
            title: '创建时间',
            key: 'updated_at',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            align: 'center',
            slot: 'action',
          }
        ],
      },

      listInfo: [],
      ruleInline: {
        name: [
          {required: true, message: '请填写标签名称', trigger: 'blur'},
          {Length: '255', message: '长度不能超过200', trigger: 'blur'}
        ],
      },
      listTotal: 0,
      pageSize: 0,
      searchFlag: 0,
    };
  },
  methods: {
    extraOperate() {
      label_util.resetGoodsLabel();
    },
    getLabelColor(color) {
      switch (color) {
        case 'one':
          return 'label-color-one';
        case 'two':
          return 'label-color-two';
        case 'three':
          return 'label-color-three';
        case 'four':
          return 'label-color-four';
        case 'five':
          return 'label-color-five';
        case 'six':
          return 'label-color-six';
        case 'seven':
          return 'label-color-seven';
        case 'eight':
          return 'label-color-eight';
        case 'nine':
          return 'label-color-nine';
        case 'ten':
          return 'label-color-ten';
      }
    },
    setLabelColor(color) {
      this.create_edit.clientFormItem.color = color;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
      this.create_edit.clientFormItem.id = '';
      this.create_edit.clientFormItem.color = '';
      this.create_edit.modal = false;
      this.create_edit.modal_loading = false;
    },
    create() {
      this.create_edit.clientFormItem.id = '';
      this.create_edit.clientFormItem.color = '';
      this.create_edit.modal_loading = false;
      this.create_edit.model_title = '编辑标签信息';
      this.create_edit.modal = true;
    },
    listEdit(row) {
      this.create_edit.clientFormItem.id = row.id;
      this.create_edit.clientFormItem.name = row.name;
      this.create_edit.clientFormItem.color = row.color;
      this.create_edit.modal = true;
      this.create_edit.modal_loading = false;
    },
    createGoodsLabel(name) {
      this.create_edit.modal_loading = true;

      if (this.create_edit.clientFormItem.color == '') {
        this.$Message.error('标签颜色必选，请留意！');
        this.create_edit.modal_loading = false;
        return;
      }

      this.$refs[name].validate((valid) => {
        if (valid) {
          apiSaveGoodsLabel(this.create_edit.clientFormItem).then((response) => {
            if (response.data.err_no == 0) {
              this.$Message.success('信息保存成功');
              this.$refs[name].resetFields();
              this.create_edit.modal = false;
              this.getList();
              this.extraOperate();
            } else {
              this.$Message.error(response.data.err_msg);
              this.create_edit.modal_loading = false;
            }
          });
        } else {
          this.create_edit.modal_loading = false;
        }
      });

    },
    searchSubmit() {
      this.getList();
    },
    cancelSearch(name) {
      this.$refs[name].resetFields();
      this.getList();
    },
    getList($page = 1) {
      let param = {
          page: $page,
          name: this.formItem.name
      };
      apiGetGoodsLabelList(param).then((response) => {
        if (response.data.err_no == 0) {
          this.listInfo = response.data.results.list;
          this.listTotal = response.data.results.total;
          this.pageSize = response.data.results.size;
        }
      });
    },
    changePage(page) {
      this.getList(page);
    },
  },
  created() {
    this.aCheck.plateName = 'common_setting';
  },
  mounted() {
    this.getList();
  },
};
</script>

<style scoped>

#modalForm {
  width: 100%;
}

.top-title {
  color: #333;
  font-size: 20px;
  line-height: 18px;
  cursor: pointer;
  font-weight: 600;
}

.client_create {
  position: absolute;
  right: 0;
  top: -8px;
}

#filter-bar {
  margin-top: 18px;
  font-size: 12px !important;
}

#label-list {
  margin-top: 12px;
  margin-bottom: 40px;
}

#label-list .page {
  margin-top: 16px;
}

.label-info {
  width: 30px;
  height: 30px;
  margin: 0 10px;
  text-align: center;
  line-height: 30px;
  color: #fff;
  cursor: pointer;
}
</style>
<style>
#goods-label .ivu-card {
  background-color: #fafafa;
}

#goods-label .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#goods-label .ivu-form-item {
  margin-bottom: 0;
}

#label-list .ivu-table-wrapper {
  border: 1px solid #f4f4f4;
  border-bottom: 0;
  border-radius: 6px;
}

#label-list.ivu-table td, .ivu-table th {
  border-bottom: 1px solid #f4f4f4;
}

#label-list .ivu-page {
  float: right;
}

#label-list .ivu-table-wrapper {
  min-height: auto;
}

#label-list .ivu-table-fixed-body {
  min-height: auto;
}

#label-list .ivu-table-overflowX, #label-list .ivu-table-tip {
  overflow-x: hidden;
}

#label-list .ivu-tag {
  border:0;
}
</style>
