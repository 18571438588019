import request from '@/plugins/http'

export function apiGetUnitList(param) {
    return request.post('/api/setting/getUnitList', param);
}

export function apiCreateUnit(param) {
    return request.post('/api/setting/createUnit', param);
}

export function apiDeleteUnit(param) {
    return request.post('/api/setting/deleteUnit', param);
}

export function apiGetUnitAll(param) {
    return request.post('/api/setting/getUnitAll', param);
}
