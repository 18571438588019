<template>
  <div id="role-setting" class="main-info">
    <div id="panel-header">
      <span class="top-title">角色设置</span>
      <Button type="primary" class="act_create" @click="createOperation('create.item')" v-if="aCheck.rightCheck('edit_role_setting')">
        创建角色
      </Button>
    </div>
    <div id="filter-bar">
      <Card dis-hover>
        <Form ref="query" :model="query" inline>
          <FormItem prop="name">
            <i-input @on-blur="searchSubmit" v-model="query.name" class="w-200" placeholder="请填写名称"/>
          </FormItem>

          <FormItem>
            <Button type="text" class="main-font-color" @click="cancelSearch('query')">清除</Button>
          </FormItem>
        </Form>
      </Card>
    </div>

    <!--列表-->
    <div id="list" class="main_list">
      <Table :columns="list.columns" :data="list.data">
        <template slot-scope="{ row }" slot="name">
          <strong><span @click="viewItem(row)" class="pointer">{{ row.name }}</span></strong>
        </template>
        <template slot-scope="{ row, index }" slot="status">
          <span :style="getStatusColor(row.status)">{{ getStatus(row.status) }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="action">
          <Button v-show="row.status==0" type="text" size="small" style="color:#ed4014;"
                  @click="viewItem(row)" v-if="aCheck.rightCheck('valid_role_setting')">
            <Icon type="ios-checkmark-circle"/>
            生效
          </Button>
          <Button v-show="row.status==1" type="text" size="small" class="main-font-color"
                  @click="reValidAction(row)" v-if="aCheck.rightCheck('valid_role_setting')">
            <Icon type="ios-refresh"/>
            重新生效
          </Button>
          <Dropdown v-if="aCheck.rightCheck('edit_role_setting')">
            <Button size="small" type="text">
              更多
              <Icon type="ios-arrow-down"></Icon>
            </Button>
            <DropdownMenu slot="list">
              <!--  只有修改和生效操作权限的人方可操作 -->
              <DropdownItem v-show="row.status==1" v-if="aCheck.rightCheck('valid_role_setting')">
                <span @click="editAction(row)" class="f12">修改</span>
              </DropdownItem>
              <DropdownItem v-show="row.status==1" v-if="aCheck.rightCheck('valid_role_setting')">
                <span @click="invalidAction(row)" class="f12">失效</span>
              </DropdownItem>
              <DropdownItem v-show="row.status==0">
                <span @click="editAction(row)" class="f12">修改</span>
              </DropdownItem>
              <DropdownItem v-show="row.status==0">
                <span @click="deleteAction(row)" class="f12">删除</span>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </template>
      </Table>
      <div class="page">
        <Page :total="list.total" :page-size="list.size" show-total show-elevator @on-change="changePage"
              :current="list.page"/>
      </div>
    </div>

    <!--创建-->
    <Modal class="role-operate" fullscreen v-model="create.modal" :title="create.title">
      <div class="modalForm">
        <Form ref="create.item" :model="create.item" :label-width="100" :rules="ruleInline" label-colon>
          <Row :gutter="16">
            <FormItem label="角色名称" prop="name">
              <Col span="10">
                <Input v-model="create.item.name" placeholder="请填写角色名称,最多20个字" maxlength="20"></Input>
              </Col>
              <Col span="4">
                <Checkbox :indeterminate="create.indeterminate" v-model="create.item.selectedAll" @click.prevent.native="moduleSelectAll">
                  模块全选
                </Checkbox>
              </Col>
            </FormItem>
          </Row>
          <Divider/>
          <Row v-for="(setting, index) in create.setting">
            <FormItem :label="setting.name" :prop="setting.name">
              <Row v-for="secondLevel in setting.children">
                <FormItem :label="secondLevel.name" class="secondLevel">
                  <CheckboxGroup :label="secondLevel.name" v-model="create.item.selected" @on-change="checkAllGroupChange">
                    <Checkbox v-for="thirdLevel in secondLevel.children" :label="thirdLevel.key">{{thirdLevel.name}}</Checkbox>
                  </CheckboxGroup>
                </FormItem>
              </Row>
            </FormItem>
            <Divider v-if="index!=create.setting.length-1"/>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="cancelCreateOperation('create.item')">关闭</Button>
        <Button type="primary" :loading="create.modal_loading" @click="createPost('create.item')">保存
        </Button>
      </div>
    </Modal>

    <!--查看-->
    <Modal class="role-operate role-view" v-model="view.modal" fullscreen :title="view.title">
      <div class="modalForm">
        <Form :model="view.item" :label-width="100" label-colon>
          <Row :gutter="16">
            <Col span="12">
              <FormItem label="角色名称">
                <span>{{ view.item.name }}</span>
              </FormItem>
            </Col>
          </Row>
          <Divider/>
          <Row :gutter="16" v-for="(setting, index) in create.setting">
            <FormItem :label="setting.name" :prop="setting.name">
              <Row v-for="secondLevel in setting.children">
                <FormItem :label="secondLevel.name" class="secondLevel">
                  <CheckboxGroup :label="secondLevel.name" v-model="view.item.selected">
                    <Checkbox disabled v-for="thirdLevel in secondLevel.children" :label="thirdLevel.key">
                      {{ thirdLevel.name }}
                    </Checkbox>
                  </CheckboxGroup>
                </FormItem>
              </Row>
            </FormItem>
            <Divider v-if="index!=create.setting.length-1"/>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="handleResetViewItem(view)">关闭</Button>
        <Button v-show="view.item.status==0" type="primary" @click="editAction(view.item)">修改</Button>
        <Button v-show="view.item.status==0" type="success" :loading="view.modal_loading"
                @click="validAction(view.item)">生效
        </Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import '@/css/common.css';

import {
  getRoleSettingConfig,
  createRole,
  getRoleList,
  validRoleStatus,
  invalidRoleStatus,
  deleteRole
} from "@/api/setting/roleSetting";

export default {
  name: "RoleSetting",
  data() {
    return {
      query: {
        name: '',
      },
      list: {
        columns: [
          {
            title: '名称',
            key: 'name',
            slot: 'name',
            align: 'center'
          },
          {
            title: '状态',
            key: 'status',
            slot: 'status',
            align: 'center'
          },
          {
            title: '操作',
            key: 'action',
            align: 'center',
            slot: 'action',
            minWidth: 60,
          }
        ],
        data: [],
        page: 1,
        total: 0,
        size: 0,
      },
      create: {
        title: '角色信息',
        modal: false,
        modal_loading: false,
        item: {
          id: 0,
          name: '',
          selected: [],
          selectedAll: false,
        },
        indeterminate: false,
      },
      allRightDetails:[],
      view: {
        title: '角色设置',
        modal: false,
        modal_loading: false,
        item: {
          id: '',
          name: '',
          selected: [],
        }
      },
      ruleInline: {
        name: [
          {required: true, message: '请填写角色名称'},
        ],
      },
    }
  },
  methods: {
    moduleSelectAll() {
      if (this.create.indeterminate) {
        this.create.item.selectedAll = false;
      } else {
        this.create.item.selectedAll = !this.create.item.selectedAll;
      }
      this.create.indeterminate = false;

      this.create.item.selected = [];
      if (this.create.item.selectedAll) {
        this.create.item.selected = this.allRightDetails;
      }
    },
    checkAllGroupChange (data) {
      if (data.length === this.allRightDetails.length) {
        this.create.indeterminate = false;
        this.create.item.selectedAll = true;
      } else if (data.length > 0) {
        this.create.indeterminate = true;
        this.create.item.selectedAll = false;
      } else {
        this.create.indeterminate = false;
        this.create.item.selectedAll = false;
      }
    },
    cancelSearch(name) {
      this.$refs[name].resetFields();
      this.list.page = 1;
      this.getMainList();
    },
    deleteAction(row) {
      this.$Modal.confirm({
        title: "角色删除",
        content: '确定要将该角色删除？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          deleteRole({id: row.id}).then((res) => {
            if (res.data.err_no == 0) {
              this.$Message.success(res.data.results);
              this.getMainList();
            } else {
              this.$Message.error(res.data.err_msg);
            }
          });
        },
        onCancel: () => {
        }
      });
    },
    invalidAction(row) {
      this.$Modal.confirm({
        title: "账号失效",
        content: '确定要将该账号失效？失效后，使用该角色的用户将无权访问系统',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          invalidRoleStatus(row).then((res) => {
            if (res.data.err_no == 0) {
              this.$Message.success(res.data.results);
              this.getMainList();
            } else {
              this.$Message.error(res.data.err_msg);
            }
          });
        },
        onCancel: () => {
        }
      });
    },
    validAction() {
      this.view.modal_loading = true;
      validRoleStatus(this.view.item).then((res) => {
        if (res.data.err_no == 0) {
          this.$Message.success(res.data.results);
          this.getMainList();
          this.view.modal = false;
        } else {
          this.$Message.error(res.data.err_msg);
          this.view.modal_loading = false;
        }
      }).catch((res) => {
        this.view.modal_loading = false;
      });
    },
    editAction(row) {
      this.view.modal = false;
      this.create.item.id = row.id;
      this.create.item.name = row.name;
      this.create.item.selected = row.selected;
      this.create.modal = true;
      this.create.modal_loading = false;

      this.checkAllGroupChange(row.selected);
    },
    handleResetViewItem(item) {
      item.modal = false;
    },
    viewItem(row) {
      this.view.modal = true;
      this.view.modal_loading = false;
      this.view.item = row;

      this.checkAllGroupChange(row.selected);
    },
    reValidAction(row) {
      this.view.item = row;

      this.$Modal.confirm({
        title: "账号重新失效",
        content: '确定要将该账号重新生效？重新生效后，用户将拥有新的权限配置',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          validRoleStatus(this.view.item).then((res) => {
            if (res.data.err_no == 0) {
              this.$Message.success(res.data.results);
              this.getMainList();
            } else {
              this.$Message.error(res.data.err_msg);
            }
          });
        },
        onCancel: () => {
        }
      });
    },
    createPost(item) {
      this.create.modal_loading = true;
      this.$refs[item].validate((valid) => {
        if (valid) {
          createRole(this.create.item).then((res) => {
            if (res.data.err_no == 0) {
              this.$Message.success(res.data.results);
              this.getMainList();
              this.create.modal = false;
            } else {
              this.$Message.error(res.data.err_msg);
              this.create.modal_loading = false;
            }
          }).catch((e) => {
            this.create.modal_loading = false;
          });
        } else {
          this.create.modal_loading = false;
        }
      });
    },
    cancelCreateOperation(item) {
      this.$refs[item].resetFields();
      this.view.modal = false;
      this.create.modal = false;
    },
    createOperation(item) {
      this.create.item.id = 0;
      this.$refs[item].resetFields();
      this.create.modal_loading = false;
      this.create.modal = true;
    },
    searchSubmit() {
      this.list.page = 1;
      this.getMainList();
    },
    getMainList() {
      let param = {
        params: {
          page: this.list.page,
          name: this.query.name,
        }
      };

      getRoleList(param).then((res) => {
        this.list.data = res.data.results.list;
        this.list.total = res.data.results.total;
        this.list.size = res.data.results.size;
      });
    },
    changePage(page) {
      this.list.page = page;
      this.getMainList();
    },
    getStatus(status) {
      let name = '';
      switch (status) {
        case 0:
          name = '待生效';
          break;
        case 1:
          name = '有效';
          break;
      }
      return name;
    },
    getStatusColor(status) {
      let name = '';
      switch (status) {
        case 0:
          name = 'color:#ed4014';
          break;
        case 1:
          name = 'color:#2775ff';
          break;
      }
      return name;
    },
    init() {
      getRoleSettingConfig().then((res) => {
        this.create.setting = res.data.results;

        for (let i=0; i<this.create.setting.length; i++) {
          for (let j=0; j< this.create.setting[i].children.length; j++) {
            if (this.create.setting[i].children[j].children.length > 0) {
              for (let m=0; m< this.create.setting[i].children[j].children.length; m++) {
                this.allRightDetails.push(this.create.setting[i].children[j].children[m].key);
              }
            }
          }
        }
      });
    }
  },
  created() {
    this.aCheck.plateName = 'common_setting';
  },
  mounted() {
    this.getMainList();
    this.init();
  }
}
</script>

<style scoped>
#role-setting {
  padding: 23px 40px;
  min-width: 960px;
  min-height: calc(100vh - 50px);
}

#panel-header {
  position: relative;
  border: none;
}

.act_create {
  position: absolute;
  right: 0;
  top: -8px;
}

.top-title {
  color: #333;
  font-size: 20px;
  line-height: 18px;
  cursor: pointer;
  font-weight: 600;
}

#filter-bar {
  margin-top: 18px;
  font-size: 12px !important;
}

#role-setting .ivu-card {
  background-color: #fafafa;
}

#role-setting .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#role-setting .ivu-form-item {
  margin-bottom: 0;
}

#role-setting .ivu-btn-small {
  font-size: 12px !important;
}

#list {
  margin-top: 12px;
  margin-bottom: 40px;
}

#list .page {
  margin-top: 16px;
}

#list .ivu-table-wrapper {
  border: 1px solid #f4f4f4;
  border-bottom: 0;
  border-radius: 6px;
}

#list.ivu-table td, .ivu-table th {
  border-bottom: 1px solid #f4f4f4;
}

#list .ivu-page {
  float: right;
}
</style>
<style>
#main_info .ivu-card {
  background-color: #fafafa;
}

.main_list .ivu-table-overflowX, .ivu-table-tip {
  overflow-x: hidden;
}

.main_list .ivu-table-wrapper {
  overflow: visible;
}

.role-operate .ivu-form-item-label {
  font-weight: bold !important;
}

.role-operate .secondLevel > .ivu-form-item-label {
  color: #2775ff !important;
}

.role-operate .ivu-row {
  display: block !important;
}

.role-operate .secondLevel .ivu-checkbox-wrapper {
  min-width: 180px;
  margin-left: 10px;
}

.role-view .ivu-checkbox-disabled.ivu-checkbox-checked .ivu-checkbox-inner {
  background-color: #2d8cf0;
  border-color: #2d8cf0;
}

.role-view .ivu-checkbox-disabled.ivu-checkbox-checked .ivu-checkbox-inner:after {
  border-color: #fff;
}

</style>
