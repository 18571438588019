import request from '@/plugins/http'

export function apiSavePictureLib(param) {
    return request.post('/api/setting/savePictureLib', param);
}

export function apiGetImgLibList(param) {
    return request.post('/api/setting/getImgLibList', param);
}

export function apiDeletePictureLib(param) {
    return request.post('/api/setting/deletePictureLib', param);
}





