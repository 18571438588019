import request from '@/plugins/http'

export function apiSaveGoodsLabel(param) {
    return request.post('/api/setting/saveGoodsLabel', param);
}

export function apiGetGoodsLabelList(param) {
    return request.post('/api/setting/getGoodsLabelList', param);
}

export function apiGetGoodsLabelAll(param) {
    return request.post('/api/setting/getGoodsLabelAll', param);
}

export function apiBindGoodsLabel(param) {
    return request.post('/api/setting/bindGoodsLabel', param);
}






