<template>
  <div id="project-info">
    <div id="panel-header">
      <span class="top-title">项目信息</span>
      <Button type="primary" class="client_create" @click="createOperate('client-item')" v-if="aCheck.rightCheck('edit_project_info')">
        创建项目
      </Button>
    </div>
    <div id="filter-bar">
      <Card dis-hover>
        <Form ref="search-form" :model="search" inline>
          <FormItem prop="name">
            <Input @on-blur="searchSubmit" v-model="search.name" class="w-200" placeholder="请填写项目名称"/>
          </FormItem>

          <FormItem prop="project_no">
            <Input @on-blur="searchSubmit" v-model="search.project_no" class="w-200" placeholder="请填写项目编号"/>
          </FormItem>

          <FormItem prop="client_id">
            <Select @on-change="searchSubmit" v-model="search.client_id"  class="w-200"  placeholder="请选择销售客户" filterable>
              <Option v-for="item in common.clientList" :value="item.client_id" :key="item.client_id">{{item.client_name}}</Option>
            </Select>
          </FormItem>

          <FormItem >
            <Button type="text" class="main-font-color" @click="cancelSearch('search-form')">清除</Button>
          </FormItem>
        </Form>
      </Card>
    </div>

    <div id="list-info">
      <Table :columns="list.columns" :data="list.data">
        <template slot-scope="{ row }" slot="project_no">
          <strong class="pointer" @click="viewInfo(row)">{{ row.project_no }}</strong>
        </template>

        <template slot-scope="{ row }" slot="status">
          <span :class="row.status == 1 ? 'success-color' : 'danger-color'">{{ row.status == 1 ? '已生效' : '待生效' }}</span>
        </template>

        <template slot-scope="{ row, index }" slot="action">
          <Button v-show="[1].includes(row.status)" type="text" size="small" @click="viewInfo(row)" class="success-color fs-12">
            <Icon type="ios-checkmark-circle"/>
            查看
          </Button>
          <Button v-show="[1].includes(row.status)" type="text" size="small" @click="invalidInfo(row)" class="danger-color fs-12" v-if="aCheck.rightCheck('valid_project_info')">
            <Icon type="ios-checkmark-circle"/>
            失效
          </Button>
          <Button v-show="[0].includes(row.status)" type="text" size="small" @click="validInfo(row)" class="danger-color fs-12" v-if="aCheck.rightCheck('valid_project_info')">
            <Icon type="ios-checkmark-circle"/>
            生效
          </Button>
          <Dropdown v-show="[0].includes(row.status)">
            <Button size="small" type="text" class="fs-12">
              更多
              <Icon type="ios-arrow-down"></Icon>
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem v-if="aCheck.rightCheck('edit_project_info')">
                <span @click="listEdit(row)" class="f12">修改</span>
              </DropdownItem>
              <DropdownItem v-if="aCheck.rightCheck('edit_project_info')">
                <span @click="listDeleteAlert(row)" class="f12">删除</span></DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </template>
      </Table>
      <div class="page">
        <Page :total="list.total" :page-size="list.size" show-total show-elevator @on-change="changePage"/>
      </div>
    </div>

    <Modal v-model="create.modal" :title="create.modal_title" width="800">
      <div class="w-p-100">
        <Form ref="client-item" :model="create.item" :label-width="100" :rules="create.ruleInline">
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="项目编号" prop="project_no">
                <Input v-model="create.item.project_no" placeholder="请填写项目编号"></Input>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="项目名称" prop="name">
                <Input v-model="create.item.name" placeholder="请填写名称"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="项目地址" prop="address">
                <Input v-model="create.item.address" placeholder="请填写地址"></Input>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="关联客户" prop="client_id">
                <Select v-model="create.item.client_id"  placeholder="请选择销售客户" filterable>
                  <Option v-for="item in common.clientList" :value="item.client_id" :key="item.client_id">{{item.client_name}}</Option>
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="24">
              <FormItem label="备注" prop="remark">
                <Input v-model="create.item.remark" placeholder="请填写备注"></Input>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="createClose()">关闭</Button>
        <Button type="primary" :loading="create.modal_loading" @click="createDepartment('client-item')">保存</Button>
      </div>

    </Modal>

    <Modal v-model="view.modal" :title="view.modal_title" width="800">
      <div class="w-p-100">
        <Form ref="view-item" :model="view.item" :label-width="100" label-colon>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="项目编号" prop="project_no">
                <span>{{ view.item.project_no }}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="项目名称" prop="name">
                <span>{{ view.item.name }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="项目地址" prop="address">
                <span>{{ view.item.address }}</span>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="关联客户" prop="remark">
                <span>{{ view.item.client_name }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="24">
              <FormItem label="备注" prop="remark">
                <span>{{ view.item.remark }}</span>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="viewClose">关闭</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import * as project_util from '@/utils/setting/project';

import {
  apiSaveProject,
  apiGetProjectList,
  apiValidProject,
  apiInValidProject,
  apiDeleteProjectInfo,
  apiGetProjectMainNo
} from "@/api/setting/projectSetting";
import * as clientsUtil from "@/utils/client";

export default {
  name: "ProjectSetting",
  data() {
    return {
      common: {
        type_info: [
          {
            "id": 1,
            "name": "销售",
          }
        ],
        clientList: []
      },
      search: {
        name: '',
        project_no: '',
        client_id: 0,
      },
      view: {
        modal: false,
        modal_title: '查看项目信息',
        item: {}
      },
      create: {
        modal_title: '创建项目信息',
        modal: false,
        modal_loading: false,
        item: {
          id: '',
          address: '',
          name: '',
          remark: '',
          project_no:'',
          client_id: 0,
        },
        ruleInline: {
          project_no: [
            {required: true, message: '请填写项目编号', trigger: 'blur'}
          ],
          name: [
            {required: true, message: '请填写项目名称', trigger: 'blur'}
          ],
          address: [
            {Length: '400', message: '长度不能超过400', trigger: 'blur'}
          ],
        },
      },
      list: {
        columns: [
          {
            title: '项目编号',
            key: 'project_no',
            slot: 'project_no',
            align: 'center',
          },
          {
            title: '项目名称',
            key: 'name',
            align: 'center',
          },
          {
            title: '关联客户',
            key: 'client_name',
            align: 'center',
          },
          {
            title: '项目地址',
            key: 'address',
            align: 'center',
          },
          {
            title: '备注',
            key: 'remark',
            align: 'center',
          },
          {
            title: '状态',
            key: 'status',
            slot: 'status',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            align: 'center',
            slot: 'action',
          }
        ],
        data: [],
        total: 0,
        size: 0,
      },
    };
  },
  methods: {
    getProjectMainNo() {
      apiGetProjectMainNo().then(res => {
        if (res.data.err_no == 0) {
          this.create.item.project_no = res.data.results;
        }
      })
    },
    getClientList() {
      clientsUtil.getClientsAll().then(res => {
        this.common.clientList = res;
      })
    },
    viewClose() {
      this.view.modal = false;
    },
    viewInfo(row) {
      this.view.item = row;
      this.view.modal = true;
    },
    validInfo(row) {
      apiValidProject({id: row.id, name: row.name}).then(res => {
        if (res.data.err_no == 0) {
          this.$Message.success('操作成功！');
          this.getMainList();
          this.extraOperate();
        } else {
          this.$Message.error(res.data.err_msg);
        }
      });
    },
    invalidInfo(row) {
      apiInValidProject({id: row.id, name: row.name}).then(res => {
        if (res.data.err_no == 0) {
          this.$Message.success('操作成功！');
          this.getMainList();
          this.extraOperate();
        } else {
          this.$Message.error(res.data.err_msg);
        }
      });
    },
    extraOperate() {
      project_util.resetProjectAll()
    },
    createClose() {
      this.create.modal_loading = false;
      this.create.modal = false;
    },
    createOperate(name) {
      this.$refs[name].resetFields();
      this.create.modal = true;
      this.create.modal_loading = false;
      this.getProjectMainNo();
    },
    listEdit(row) {
      this.create.item = row;
      this.create.modal_loading = false;
      this.create.modal = true;
    },
    listDeleteAlert(row) {
      this.$Modal.confirm({
        title: "删除项目信息",
        content: '确定删除项目信息？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          this.deleteListInfo(row);
        },
        onCancel: () => {
        }
      });
    },
    deleteListInfo(row) {
      apiDeleteProjectInfo({id: row.id, name: row.name}).then((response) => {
        if (response.data.err_no == 0) {
          this.$Message.success("删除操作成功！");
          this.getMainList();
          this.extraOperate();
        } else {
          this.$Message.error(response.data.err_msg);
        }
      });
    },
    createDepartment(name) {
      this.create.modal_loading = true;

      this.$refs[name].validate((valid) => {
        if (!valid) {
          this.create.modal_loading = false;
          return;
        }

        apiSaveProject(this.create.item).then((response) => {
          if (response.data.err_no == 0) {
            this.$Message.success('信息保存成功');

            this.create.modal = false;
            this.getMainList();
            this.extraOperate();

          } else {
            this.$Message.error(response.data.err_msg);
            this.create.modal_loading = false;
          }
        }).catch((e) => {
          this.create.modal_loading = false;
        });
      });

    },
    searchSubmit() {
      this.getMainList();
    },
    cancelSearch(name) {
      this.$refs[name].resetFields();
      this.getMainList();
    },
    getMainList($page = 1) {
      let param = {
        page: $page,
        name: this.search.name,
        project_no: this.search.project_no,
      };
      apiGetProjectList(param).then((response) => {
        if (response.data.err_no == 0) {
          this.list.data = response.data.results.list;
          this.list.total = response.data.results.total;
          this.list.size = response.data.results.size;
        }
      });
    },
    changePage(page) {
      this.getMainList(page);
    }
  },
  mounted() {
    this.clientFormItembak = this.clientFormItem;
    this.getMainList();

    this.getClientList();
  },
  created() {
    this.aCheck.plateName = 'common_setting';
  },
};
</script>

<style scoped>
#project-info {
  padding: 23px 40px;
  min-width: 960px;
  min-height: calc(100vh - 50px);
}

#panel-header {
  position: relative;
  border: none;
}

.top-title {
  color: #333;
  font-size: 20px;
  line-height: 18px;
  cursor: pointer;
  font-weight: 600;
}

.client_create {
  position: absolute;
  right: 0;
  top: -8px;
}

#filter-bar {
  margin-top: 18px;
  font-size: 12px !important;
}

#list-info {
  margin-top: 12px;
  margin-bottom: 40px;
}

#list-info .page {
  margin-top: 16px;
}
</style>
<style>
#project-info .ivu-card {
  background-color: #fafafa;
}

#project-info .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#project-info .ivu-form-item {
  margin-bottom: 0;
}

#list-info .ivu-table-wrapper {
  border: 1px solid #f4f4f4;
  border-bottom: 0;
  border-radius: 6px;
}

#list-info.ivu-table td, .ivu-table th {
  border-bottom: 1px solid #f4f4f4;
}

#list-info .ivu-page {
  float: right;
}

#list-info .ivu-table-wrapper {
  min-height: auto;
}

#list-info .ivu-table-fixed-body {
  min-height: auto;
}

#list-info .ivu-table-overflowX, #list-info .ivu-table-tip {
  overflow-x: hidden;
}

#list-info .ivu-table-wrapper {
  overflow: visible;
}
</style>
